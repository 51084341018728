import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider, theme } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import 'antd/dist/reset.css';
import AppRoutes from './routes';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';
import './App.less';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const App: React.FC = () => {
  const { theme: currentTheme, toggleTheme } = useTheme();
  const { defaultAlgorithm, darkAlgorithm } = theme;

  return (
    <ConfigProvider 
      locale={zhCN}
      theme={{
        algorithm: currentTheme === 'dark' ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: '#1890ff',
        },
      }}
    >
      <AppRoutes />
    </ConfigProvider>
  );
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <StyleProvider>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </StyleProvider>
    </BrowserRouter>
  </React.StrictMode>
); 