import React, { useState, useRef, useEffect } from 'react';
import { Layout, Tree, Button, Card, Tabs, Divider } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, UserOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import ThemeSwitch from '../../components/ThemeSwitch';
import './style.less';
import { message } from 'antd';

const { Header, Sider, Content } = Layout;
const { TabPane } = Tabs;

interface UIComponent {
  id: string;
  name: string;
  type: 'dock' | 'ribbon' | 'floating';
  category: string;
  thumbnail: string;
  isAdded: boolean;
  description?: string;
  position?: { x: number; y: number };
}

const categories = [
  { key: '1', name: '路线设计' },
  { key: '2', name: '桥梁设计' },
  { key: '3', name: '隧道设计' },
  { key: '4', name: '交通设施' },
  { key: '5', name: '路基路面' },
  { key: '6', name: '排水工程' },
  { key: '7', name: '智能交通' },
];

const TemplateDetail: React.FC = () => {
  const navigate = useNavigate();
  const { templateId } = useParams<{ templateId: string }>();
  const [selectedComponents, setSelectedComponents] = useState<UIComponent[]>([]);
  const [siderWidth, setSiderWidth] = useState(400);  // 右侧组件列表的默认宽度
  const [isDragging, setIsDragging] = useState(false);
  const dragStartX = useRef<number>(0);
  const dragStartWidth = useRef<number>(400);

  // 模拟UI组件数据
  const uiComponents: UIComponent[] = [
    {
      id: 'dock1',
      name: '项目管理器',
      type: 'dock',
      category: '路线设计',
      thumbnail: '/thumbnails/dock-project.png',
      isAdded: false,
      description: '管理项目文件和结构',
    },
    {
      id: 'ribbon1',
      name: '路线工具栏',
      type: 'ribbon',
      category: '路线设计',
      thumbnail: '/thumbnails/ribbon-route.png',
      isAdded: false,
      description: '路线设计常用工具',
    },
    {
      id: 'dock2',
      name: '桥梁构件库',
      type: 'dock',
      category: '桥梁设计',
      thumbnail: '/thumbnails/dock-bridge.png',
      isAdded: false,
      description: '标准桥梁构件管理',
    },
    {
      id: 'floating2',
      name: '桥梁参数面板',
      type: 'floating',
      category: '桥梁设计',
      thumbnail: '/thumbnails/floating-bridge.png',
      isAdded: false,
      description: '桥梁设计参数配置',
    },
    {
      id: 'dock3',
      name: '隧道断面库',
      type: 'dock',
      category: '隧道设计',
      thumbnail: '/thumbnails/dock-tunnel.png',
      isAdded: false,
      description: '隧道标准断面管理',
    },
    // ... 添加更多组件
  ];

  // 处理组件添加
  const handleAddComponent = (component: UIComponent) => {
    if (!component.isAdded) {
      setSelectedComponents([...selectedComponents, component]);
      component.isAdded = true;
    }
  };

  // 处理组件移除
  const handleRemoveComponent = (componentId: string) => {
    setSelectedComponents(selectedComponents.filter(c => c.id !== componentId));
    const component = uiComponents.find(c => c.id === componentId);
    if (component) {
      component.isAdded = false;
    }
  };

  // 处理拖拽开始
  const handleDragStart = (e: React.MouseEvent) => {
    setIsDragging(true);
    dragStartX.current = e.clientX;
    dragStartWidth.current = siderWidth;
    document.body.style.cursor = 'col-resize';
  };

  // 处理拖拽过程
  const handleDrag = (e: MouseEvent) => {
    if (!isDragging) return;
    const deltaX = dragStartX.current - e.clientX;
    const newWidth = Math.max(300, Math.min(800, dragStartWidth.current + deltaX));
    setSiderWidth(newWidth);
  };

  // 处理拖拽结束
  const handleDragEnd = () => {
    setIsDragging(false);
    document.body.style.cursor = 'default';
  };

  // 添加拖拽事件监听
  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleDrag);
      window.addEventListener('mouseup', handleDragEnd);
    }
    return () => {
      window.removeEventListener('mousemove', handleDrag);
      window.removeEventListener('mouseup', handleDragEnd);
    };
  }, [isDragging]);

  // 处理完成按钮点击
  const handleComplete = () => {
    // 这里添加保存修改的逻辑
    message.success('修改已保存');
    navigate(-1);  // 返回上一页
  };

  return (
    <Layout className="template-detail">
      <Header className="header">
        <div className="header-left">
          <Button 
            type="link" 
            icon={<ArrowLeftOutlined />} 
            onClick={() => navigate(-1)}
            className="back-button"
          >
            返回
          </Button>
          <span className="logo">超融合BIM图形平台</span>
          <div className="divider" />
          <span className="page-title">组件模板详情</span>
        </div>
        <div className="header-right">
          <ThemeSwitch />
        </div>
      </Header>
      <Layout className="main-content">
        {/* 左侧CAD预览区 */}
        <Content className="preview-area">
          <div className="cad-container">
            <div className="placeholder-text">CAD平台预览区</div>
            {/* 显示已添加的组件 */}
            <div className="added-components">
              {selectedComponents.map(component => (
                <div key={component.id} className="added-component">
                  <img src={component.thumbnail} alt={component.name} />
                  <Button 
                    type="text" 
                    danger 
                    icon={<DeleteOutlined />}
                    onClick={() => handleRemoveComponent(component.id)}
                  />
                </div>
              ))}
            </div>
          </div>
        </Content>

        {/* 右侧组件列表 */}
        <Sider 
          width={siderWidth} 
          className="components-sider"
          style={{ flex: `0 0 ${siderWidth}px` }}
        >
          {/* 拖拽分隔线 */}
          <div
            className="resize-handle"
            style={{ left: '-2px' }}  // 将分隔线放在 Sider 的左边
            onMouseDown={handleDragStart}
          />
          
          <div className="sider-header">
            <h3>组件列表</h3>
            <Button 
              type="primary"
              onClick={handleComplete}
            >
              完成
            </Button>
          </div>
          <Tabs defaultActiveKey="1">
            {categories.map(category => (
              <TabPane tab={category.name} key={category.key}>
                <div className="components-grid">
                  {uiComponents
                    .filter(c => c.category === category.name)
                    .map(component => (
                      <Card
                        key={component.id}
                        hoverable
                        className="component-card"
                        cover={<img alt={component.name} src={component.thumbnail} />}
                      >
                        <Card.Meta
                          title={component.name}
                          description={
                            <div className="component-info">
                              <div className="component-type">
                                {component.type === 'dock' && '停靠面板'}
                                {component.type === 'ribbon' && '功能区'}
                                {component.type === 'floating' && '浮动窗口'}
                              </div>
                              <div className="component-desc">{component.description}</div>
                            </div>
                          }
                        />
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          disabled={component.isAdded}
                          onClick={() => handleAddComponent(component)}
                        >
                          {component.isAdded ? '已添加' : '添加'}
                        </Button>
                      </Card>
                    ))}
                </div>
              </TabPane>
            ))}
          </Tabs>
        </Sider>
      </Layout>
    </Layout>
  );
};

export default TemplateDetail; 