// Define SVG content
const svgContent = `<svg width="1920" height="1080" viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <linearGradient id="bgGradient" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop offset="0%" style="stop-color:#e6f7ff;stop-opacity:1" />
      <stop offset="50%" style="stop-color:#bae7ff;stop-opacity:1" />
      <stop offset="100%" style="stop-color:#91d5ff;stop-opacity:1" />
    </linearGradient>
    
    <!-- 道路网格模式 -->
    <pattern id="roadPattern" width="400" height="400" patternUnits="userSpaceOnUse">
      <!-- 主干道 -->
      <path d="M0,200 L400,200" stroke="#1890ff" stroke-width="8" opacity="0.2"/>
      <path d="M200,0 L200,400" stroke="#1890ff" stroke-width="8" opacity="0.2"/>
      <!-- 辅路 -->
      <path d="M0,100 L400,100" stroke="#1890ff" stroke-width="4" opacity="0.15" stroke-dasharray="20,20"/>
      <path d="M0,300 L400,300" stroke="#1890ff" stroke-width="4" opacity="0.15" stroke-dasharray="20,20"/>
      <path d="M100,0 L100,400" stroke="#1890ff" stroke-width="4" opacity="0.15" stroke-dasharray="20,20"/>
      <path d="M300,0 L300,400" stroke="#1890ff" stroke-width="4" opacity="0.15" stroke-dasharray="20,20"/>
    </pattern>

    <!-- 交通节点模式 -->
    <pattern id="nodePattern" width="400" height="400" patternUnits="userSpaceOnUse">
      <!-- 主要交叉口 -->
      <circle cx="200" cy="200" r="15" fill="#1890ff" opacity="0.3"/>
      <!-- 次要交叉口 -->
      <circle cx="100" cy="100" r="8" fill="#1890ff" opacity="0.2"/>
      <circle cx="300" cy="100" r="8" fill="#1890ff" opacity="0.2"/>
      <circle cx="100" cy="300" r="8" fill="#1890ff" opacity="0.2"/>
      <circle cx="300" cy="300" r="8" fill="#1890ff" opacity="0.2"/>
    </pattern>

    <!-- BIM元素模式 -->
    <pattern id="bimPattern" width="200" height="200" patternUnits="userSpaceOnUse">
      <path d="M50,50 L150,50 L150,150 L50,150 Z" fill="none" stroke="#1890ff" stroke-width="2" opacity="0.15"/>
      <path d="M70,70 L130,70 L130,130 L70,130 Z" fill="none" stroke="#1890ff" stroke-width="1" opacity="0.15"/>
    </pattern>
  </defs>
  
  <!-- 背景渐变 -->
  <rect width="100%" height="100%" fill="url(#bgGradient)"/>
  
  <!-- 道路网络层 -->
  <rect width="100%" height="100%" fill="url(#roadPattern)"/>
  
  <!-- 交通节点层 -->
  <rect width="100%" height="100%" fill="url(#nodePattern)"/>
  
  <!-- BIM元素层 -->
  <rect width="100%" height="100%" fill="url(#bimPattern)"/>
  
  <!-- 动态路线 -->
  <g stroke="#1890ff" opacity="0.3">
    <!-- 曲线道路 -->
    <path d="M0,400 Q480,200 960,540 T1920,400" stroke-width="6" fill="none"/>
    <path d="M0,600 Q480,800 960,540 T1920,600" stroke-width="6" fill="none"/>
    
    <!-- 交通流动画 -->
    <path d="M0,400 Q480,200 960,540 T1920,400" stroke-width="2" fill="none" stroke-dasharray="20,20">
      <animate attributeName="stroke-dashoffset" from="0" to="80" dur="3s" repeatCount="indefinite"/>
    </path>
    <path d="M0,600 Q480,800 960,540 T1920,600" stroke-width="2" fill="none" stroke-dasharray="20,20">
      <animate attributeName="stroke-dashoffset" from="0" to="-80" dur="3s" repeatCount="indefinite"/>
    </path>
  </g>

  <!-- 装饰性元素 -->
  <g transform="translate(960,540)" fill="none" stroke="#1890ff" opacity="0.25">
    <!-- 中心交通枢纽 -->
    <circle r="100" stroke-width="4"/>
    <circle r="80" stroke-width="2"/>
    <circle r="60" stroke-width="1"/>
    
    <!-- 方向指示 -->
    <path d="M-120,0 L120,0" stroke-width="2" stroke-dasharray="10,10"/>
    <path d="M0,-120 L0,120" stroke-width="2" stroke-dasharray="10,10"/>
    
    <!-- 角度指示器 -->
    <path d="M0,0 L80,0 A80,80 0 0,1 56.57,56.57" stroke-width="1"/>
    <path d="M0,0 L-80,0 A80,80 0 0,0 -56.57,-56.57" stroke-width="1"/>
  </g>

  <!-- 信息点 -->
  <g fill="#1890ff">
    <circle cx="480" cy="270" r="4" opacity="0.5"/>
    <circle cx="1440" cy="270" r="4" opacity="0.5"/>
    <circle cx="480" cy="810" r="4" opacity="0.5"/>
    <circle cx="1440" cy="810" r="4" opacity="0.5"/>
    <circle cx="960" cy="540" r="6" opacity="0.7"/>
  </g>
</svg>`;

// Create Base64 string with encoding
export const trafficBgBase64 = `data:image/svg+xml;base64,${btoa(encodeURIComponent(svgContent).replace(/%([0-9A-F]{2})/g,
    (match, p1) => String.fromCharCode(parseInt(p1, 16))))}`; 