// 生成占位图片的函数
export const generatePlaceholderImage = (text: string, width = 200, height = 150) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  
  if (ctx) {
    // 背景
    ctx.fillStyle = '#f0f2f5';
    ctx.fillRect(0, 0, width, height);
    
    // 文字
    ctx.fillStyle = '#1890ff';
    ctx.font = '16px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(text, width/2, height/2);
  }
  
  return canvas.toDataURL();
};

// 生成图标的函数
export const generateIconImage = (text: string, size = 128) => {
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d');
  
  if (ctx) {
    // 背景
    ctx.fillStyle = '#1890ff';
    ctx.beginPath();
    ctx.arc(size/2, size/2, size/2, 0, Math.PI * 2);
    ctx.fill();
    
    // 文字
    ctx.fillStyle = '#ffffff';
    ctx.font = `${size/3}px Arial`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(text.charAt(0), size/2, size/2);
  }
  
  return canvas.toDataURL();
};
