import React from 'react';
import { Card, Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { trafficBgBase64 } from '../../assets/images/traffic-bg';
import './style.less';
import { useNavigate } from 'react-router-dom';

interface LoginForm {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleSubmit = async (values: LoginForm) => {
    try {
      // 这里添加实际的登录逻辑
      console.log('登录信息:', values);
      message.success('登录成功！');
      navigate('/projects');
    } catch (error) {
      message.error('登录失败，请重试！');
    }
  };


  React.useEffect(() => {
    const container = document.querySelector('.login-container') as HTMLElement;
    if (container) {
      container.style.backgroundImage = `url("${trafficBgBase64}")`;
      console.log('Background image set to container');
    }
  }, []);

  return (
    <div className="login-container">
      <Card title="超融合BIM图形平台" className="login-card" bordered={false}>
        <Form
          form={form}
          name="login"
          onFinish={handleSubmit}
          autoComplete="off"
          size="large"
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: '请输入用户名！' }]}
          >
            <Input 
              prefix={<UserOutlined />} 
              placeholder="用户名" 
              size="large"
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: '请输入密码！' }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="密码"
              size="large"
              autoComplete="new-password"
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" block size="large">
              登录
            </Button>
          </Form.Item>
          
        </Form>
      </Card>
    </div>
  );
};

export default Login; 