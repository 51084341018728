import React, { useState } from 'react';
import { Layout, Tree, Space, Button, Input, Card, Tabs, Dropdown, message } from 'antd';
import { FolderOutlined, FileOutlined, ArrowLeftOutlined, UserOutlined, SearchOutlined, PlusOutlined, DeleteOutlined, SwapOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import ThemeSwitch from '../../components/ThemeSwitch';
import './style.less';
import type { MenuProps } from 'antd';

const { Header, Sider, Content } = Layout;

interface ComponentNode {
  title: string;
  key: string;
  children?: ComponentNode[];
  icon?: React.ReactNode;
  isLeaf?: boolean;
}

// 添加构件库接口
interface ComponentLibrary {
  id: string;
  name: string;
  type: string;
  count: number;
}

const ComponentDetail: React.FC = () => {
  const navigate = useNavigate();
  const { componentId } = useParams<{ componentId: string }>();
  const [selectedComponent, setSelectedComponent] = useState<string>();
  const [searchText, setSearchText] = useState('');
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [currentLibrary, setCurrentLibrary] = useState<ComponentLibrary>({
    id: '1',
    name: '标准构件库',
    type: '系统默认',
    count: 128
  });

  // 模拟用户信息
  const userInfo = {
    name: '张三',
    role: '管理员',
  };

  // 模拟构件库列表
  const componentLibraries: ComponentLibrary[] = [
    {
      id: '1',
      name: '标准构件库',
      type: '系统默认',
      count: 128
    },
    {
      id: '2',
      name: '企业构件库',
      type: '企业共享',
      count: 256
    },
    {
      id: '3',
      name: '项目构件库',
      type: '项目专用',
      count: 64
    },
    {
      id: '4',
      name: '个人构件库',
      type: '个人收藏',
      count: 32
    }
  ];

  // 构件库切换菜单项
  const libraryMenuItems: MenuProps['items'] = componentLibraries.map(library => ({
    key: library.id,
    label: (
      <div className="library-menu-item">
        <div className="library-info">
          <span className="library-name">{library.name}</span>
          <span className="library-type">{library.type}</span>
        </div>
        <span className="library-count">{library.count}个构件</span>
      </div>
    ),
    disabled: library.id === currentLibrary.id
  }));

  // 处理构件库切换
  const handleLibraryChange: MenuProps['onClick'] = ({ key }) => {
    const library = componentLibraries.find(lib => lib.id === key);
    if (library) {
      setCurrentLibrary(library);
      message.success(`已切换到${library.name}`);
    }
  };

  // 构件类别树
  const categoryTreeData: ComponentNode[] = [
    {
      title: '桥梁构件',
      key: 'bridge',
      icon: <FolderOutlined />,
      children: [
        { title: '上部结构', key: 'bridge-upper' },
        { title: '下部结构', key: 'bridge-lower' },
        { title: '附属结构', key: 'bridge-auxiliary' },
      ]
    },
    {
      title: '隧道构件',
      key: 'tunnel',
      icon: <FolderOutlined />,
      children: [
        { title: '衬砌结构', key: 'tunnel-lining' },
        { title: '防水结构', key: 'tunnel-waterproof' },
      ]
    }
  ];

  // 构件详情树
  const getComponentTreeData = (categoryKey: string): ComponentNode[] => {
    const data: Record<string, ComponentNode[]> = {
      'bridge-upper': [
        {
          title: '箱梁构件',
          key: 'box-girder',
          children: [
            {
              title: '标准箱梁',
              key: 'standard-box-girder',
              children: [
                { title: '截面信息', key: 'section-info', isLeaf: true },
                { title: '放置点', key: 'placement-points', isLeaf: true }
              ]
            }
          ]
        }
      ],
      'bridge-lower': [
        {
          title: '桥墩构件',
          key: 'pier',
          children: [
            {
              title: '圆形墩',
              key: 'round-pier',
              children: [
                { title: '截面信息', key: 'section-info', isLeaf: true },
                { title: '放置点', key: 'placement-points', isLeaf: true }
              ]
            }
          ]
        }
      ]
    };
    return data[categoryKey] || [];
  };

  const [componentTreeData, setComponentTreeData] = useState<ComponentNode[]>([]);

  // 处理类别选择
  const handleCategorySelect = (selectedKeys: React.Key[]) => {
    const categoryKey = selectedKeys[0] as string;
    if (categoryKey) {
      setComponentTreeData(getComponentTreeData(categoryKey));
    }
  };

  // 处理构件选择
  const handleComponentSelect = (selectedKeys: React.Key[]) => {
    setSelectedComponent(selectedKeys[0] as string);
  };

  // 搜索节点的函数
  const getParentKey = (key: string, tree: ComponentNode[]): string => {
    let parentKey = '';
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some(item => item.key === key)) {
          parentKey = node.key;
        } else {
          const result = getParentKey(key, node.children);
          if (result) {
            parentKey = result;
          }
        }
      }
    }
    return parentKey;
  };

  // 处理搜索
  const handleSearch = (value: string) => {
    setSearchText(value);
    const expandedKeys = value ? findExpandedKeys(value) : [];
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(true);
  };

  // 修改查找需要展开的节点的函数
  const findExpandedKeys = (value: string): string[] => {
    const expandedKeysSet = new Set<string>();
    const searchNodes = (nodes: ComponentNode[]) => {
      nodes.forEach(node => {
        if (node.title.toLowerCase().includes(value.toLowerCase())) {
          const parentKey = getParentKey(node.key, categoryTreeData);
          if (parentKey) {
            expandedKeysSet.add(parentKey);
          }
        }
        if (node.children) {
          searchNodes(node.children);
        }
      });
    };
    searchNodes(categoryTreeData);
    return Array.from(expandedKeysSet); // 使用 Array.from 替代展开运算符
  };

  // 处理展开/收起
  const handleExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  // 过滤树节点
  const filterTreeNode = (node: ComponentNode): boolean => {
    if (!searchText) return true;
    return node.title.toLowerCase().includes(searchText.toLowerCase());
  };

  return (
    <Layout className="component-detail">
      <Header className="header">
        <div className="header-left">
          <Button 
            type="link" 
            icon={<ArrowLeftOutlined />} 
            onClick={() => navigate(-1)}
            className="back-button"
          >
            返回
          </Button>
          <span className="logo">超融合BIM图形平台</span>
          <div className="divider" />
          <span className="page-title">构件库详情</span>
        </div>
        <div className="header-right">
          <Space>
            <Dropdown 
              menu={{ 
                items: libraryMenuItems,
                onClick: handleLibraryChange
              }} 
              placement="bottomRight"
            >
              <Button icon={<SwapOutlined />}>
                {currentLibrary.name}
                <span className="library-count">({currentLibrary.count})</span>
              </Button>
            </Dropdown>
            <ThemeSwitch />
            <Button type="link" className="user-info">
              <UserOutlined /> {userInfo.name}
              <span className="user-role">({userInfo.role})</span>
            </Button>
          </Space>
        </div>
      </Header>
      <Layout>
        <Sider width={250} className="category-sider">
          <div className="sider-header">构件类别</div>
          <div className="search-box">
            <Input
              placeholder="搜索构件"
              prefix={<SearchOutlined />}
              onChange={e => handleSearch(e.target.value)}
              allowClear
            />
          </div>
          <Tree
            showIcon
            defaultExpandAll
            treeData={categoryTreeData}
            onSelect={handleCategorySelect}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onExpand={handleExpand}
            filterTreeNode={filterTreeNode}
          />
        </Sider>
        <Sider width={250} className="component-sider">
          <div className="sider-header">构件列表</div>
          <Tree
            showIcon
            treeData={componentTreeData}
            onSelect={handleComponentSelect}
          />
        </Sider>
        <Content className="preview-area">
          <div className="model-container">
            {/* 这里集成实际的3D模型预览组件 */}
            <div className="placeholder-text">
              {selectedComponent ? `预览构件: ${selectedComponent}` : '请选择构件'}
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ComponentDetail; 