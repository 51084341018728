import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Input, Select, Card, Popconfirm, message, Layout, Menu, Dropdown, Modal, Form, Carousel, Slider, Tag } from 'antd';
import { 
  SearchOutlined, 
  DeleteOutlined, 
  EditOutlined, 
  UserOutlined, 
  LogoutOutlined, 
  PlusOutlined, 
  MenuFoldOutlined, 
  MenuUnfoldOutlined, 
  AppstoreOutlined, 
  FileAddOutlined, 
  TeamOutlined, 
  BoxPlotOutlined, 
  SkinOutlined, 
  RightOutlined, 
  DownOutlined, 
  AppstoreAddOutlined, 
  PoweroffOutlined, 
  ThunderboltOutlined, 
  ClockCircleOutlined, 
  TableOutlined,
  ProjectOutlined,
  CloudOutlined,
  CheckOutlined  // 添加这个导入
} from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import type { MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import './style.less';
import ThemeSwitch from '../../components/ThemeSwitch';
import { generatePlaceholderImage, generateIconImage } from '../../utils/generatePlaceholderImage';

const { Search } = Input;
const { Option } = Select;
const { Header, Sider } = Layout;

interface Project {
  key: string;
  id: number;
  name: string;
  code: string;
  type: string;
  department: string;
  owner: string;
  manager: string;
  creator: string;
  createTime: string;
}

// 修改构件接定义
interface Component3D {
  id: string;
  name: string;
  thumbnail: string;
  // type: string;
  group: 'personal' | 'enterprise' | 'public';
  category: '桥梁' | '隧道' | '道路' | '交安' | '涵洞' | '市政管线' | '道路机电' | '自定义';
}

// 扩展材质接口定义
interface Material {
  id: string;
  name: string;
  thumbnail: string;
  category: string;
  type: 'my' | 'platform';  // 添加类型字段
  baseSettings: {
    color: string;
    ambientColor: string;
    opacity: number;
  };
  textureSettings: {
    textureName: string;
    size: {
      x: number;
      y: number;
    };
    offset: {
      x: number;
      y: number;
    };
    mappingType: 'parametric' | 'heightVertical' | 'planar' | 'orientedVertical' | 'cubic' | 'spherical' | 'cylindrical' | 'solid';
  };
}

// 修改组件模板接口定义
interface Template {
  id: string;
  name: string;
  thumbnail: string;
  type: string;
  description: string;
  category: 'my' | 'market';  // 添加分类字段
}

// 扩展应用接口定义
interface TrafficApp {
  id: string;
  name: string;
  icon: string;
  category: string;
  description: string;
  version: string;
  developer: string;
  price: number;
  features: string[];
  screenshots: string[];
  subscribed: boolean;
  loaded?: boolean;  // 添加加载状态
}

// 添加云存储套餐接口
interface StoragePlan {
  id: string;
  name: string;
  capacity: number;  // GB
  price: number;     // 元/年
  features: string[];
  recommended?: boolean;
}

// 生成图片资源
const thumbnails = {
  // 构件库缩略图
  bridge1: generatePlaceholderImage('桥梁构件', 300, 200),
  tunnel1: generatePlaceholderImage('隧道构件', 300, 200),
  road1: generatePlaceholderImage('道路构件', 300, 200),
  custom1: generatePlaceholderImage('自定义构件', 300, 200),
  
  // 材质库缩略图
  material1: generatePlaceholderImage('混凝土材质', 300, 200),
  material2: generatePlaceholderImage('钢材材质', 300, 200),
  material3: generatePlaceholderImage('沥青材质', 300, 200),
  
  // 模板缩略图
  'bridge-template': generatePlaceholderImage('桥梁模板', 300, 200),
  'tunnel-template': generatePlaceholderImage('隧道模板', 300, 200),
  'road-template': generatePlaceholderImage('道路模板', 300, 200),
};

// 生成应用图标
const icons = {
  'route-optimizer': generateIconImage('路'),
  'bridge-inspector': generateIconImage('桥'),
  'tunnel-simulator': generateIconImage('隧'),
  'traffic-analyzer': generateIconImage('交'),
};

// 添加最近打开项目的接口
interface RecentProject extends Project {
  lastOpenTime: string;
}

const ProjectList: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedDepartment, setSelectedDepartment] = useState<string>('');
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editingProject, setEditingProject] = useState<Project | null>(null);
  const [editForm] = Form.useForm();
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [addForm] = Form.useForm();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedNav, setSelectedNav] = useState('all');
  const [templates, setTemplates] = useState<Template[]>([
    {
      id: 't1',
      name: '标准桥梁模板',
      thumbnail: thumbnails['bridge-template'],
      type: '桥梁工程',
      description: '适用于常规桥梁工程的标准模板',
      category: 'my'
    },
    {
      id: 't2',
      name: '隧道施工模板',
      thumbnail: thumbnails['tunnel-template'],
      type: '隧道工程',
      description: '隧道施工标准化模板',
      category: 'my'
    },
    {
      id: 't3',
      name: '道路设计模板',
      thumbnail: thumbnails['road-template'],
      type: '道路工程',
      description: '道路工程设计标准模板',
      category: 'my'
    },
    {
      id: 't4',
      name: '交通设施模板',
      thumbnail: generatePlaceholderImage('交通设施模板', 300, 200),
      type: '交通工程',
      description: '交通设施布设标准模板',
      category: 'my'
    },
    {
      id: 't5',
      name: '智能交通模板',
      thumbnail: generatePlaceholderImage('智能交通模板', 300, 200),
      type: '智能化工程',
      description: '智能交通系统集成模板',
      category: 'market'
    }
  ]);

  // 模拟用户信息
  const userInfo = {
    name: '张三',
    role: '管理员',
    department: '工程部',
  };

  // 用户菜单项
  const userMenuItems: MenuProps['items'] = [
    {
      key: 'profile',
      icon: <UserOutlined />,
      label: '个人信息',
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: '退出登录',
      danger: true,
    },
  ];

  // 导航菜单项
  const navItems: MenuProps['items'] = [
    {
      key: 'all',
      icon: <AppstoreOutlined />,
      label: '首页',
    },
    {
      type: 'divider',
    },
    {
      key: 'recent',  // 添加最近访问选项
      icon: <ClockCircleOutlined />,
      label: '最近访问',
    },
    {
      key: 'created',
      icon: <FileAddOutlined />,
      label: '我创建的',
    },
    {
      key: 'participated',
      icon: <TeamOutlined />,
      label: '我参与的',
    },
    {
      type: 'divider',
    },
    {
      key: 'components',
      icon: <BoxPlotOutlined />,
      label: '构件库',
    },
    {
      key: 'materials',
      icon: <SkinOutlined />,
      label: '材质库',
    },
    {
      type: 'divider',
    },
    {
      key: 'templates',
      icon: <AppstoreAddOutlined />,
      label: '组件模板',
    },
    {
      key: 'appstore',
      icon: <AppstoreOutlined />,
      label: '应用市场',
    },
    {
      type: 'divider'
    },
    {
      key: 'storage',
      icon: <CloudOutlined />,
      label: (
        <div 
          style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center' 
          }}
        >
          <span>云存储扩容</span>
          <Tag color="blue" style={{ marginLeft: 8 }}>新</Tag>
        </div>
      ),
      onClick: () => setStorageModalVisible(true)
    }
  ];

  // 处理菜单点击
  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'profile':
        message.info('个人信息功能开发中');
        break;
      case 'logout':
        message.success('已安全退出');
        navigate('/login');
        break;
      default:
        break;
    }
  };

  // 处理导航选择
  const handleNavSelect = ({ key }: { key: string }) => {
    setSelectedNav(key);
    // 根据选择过滤项目列表
    let filtered = [...projects];
    switch (key) {
      case 'recent':
        filtered = recentProjects;
        break;
      case 'created':
        filtered = projects.filter(p => p.creator === userInfo.name);
        break;
      case 'participated':
        filtered = projects.filter(p => p.manager === userInfo.name);
        break;
      case 'all':
      default:
        filtered = projects;
        break;
    }
    setFilteredProjects(filtered);
  };

  // 生成模拟数据
  useEffect(() => {
    // 创建一些固定的示例数据
    const sampleProjects: Project[] = [
      {
        key: '1',
        id: 1,
        name: '杭州湾跨跨海大桥维护项目',
        code: 'PRJ2024001',
        type: '桥梁工程',
        department: '桥梁养护部',
        owner: '杭州市交通局',
        manager: userInfo.name,  // 当前用户作为负责人
        creator: '王工',
        createTime: '2024-01-15',
      },
      {
        key: '2',
        id: 2,
        name: '沪杭高速智能改造工程',
        code: 'PRJ2024002',
        type: '道路工程',
        department: '智能化部',
        owner: '上海市交通委',
        manager: '李工',
        creator: userInfo.name,  // 当前用户创建
        createTime: '2024-01-20',
      },
      {
        key: '3',
        id: 3,
        name: '秦岭终南山隧道监测项目',
        code: 'PRJ2024003',
        type: '隧道工程',
        department: '隧道工程部',
        owner: '陕西省交通厅',
        manager: userInfo.name,
        creator: '张工',
        createTime: '2024-02-01',
      },
      {
        key: '4',
        id: 4,
        name: '京沪高速改扩建工程',
        code: 'PRJ2024004',
        type: '道路工程',
        department: '工程部',
        owner: '交通运输部',
        manager: '赵工',
        creator: userInfo.name,
        createTime: '2024-02-10',
      },
      {
        key: '5',
        id: 5,
        name: '青藏高原生态桥项目',
        code: 'PRJ2024005',
        type: '桥梁工程',
        department: '桥梁工程部',
        owner: '青海省交通厅',
        manager: userInfo.name,
        creator: '周工',
        createTime: '2024-02-15',
      },
      {
        key: '6',
        id: 6,
        name: '珠港澳大桥维护工程',
        code: 'PRJ2024006',
        type: '桥梁工程',
        department: '养护部',
        owner: '广东省交通厅',
        manager: '吴工',
        creator: userInfo.name,
        createTime: '2024-02-20',
      },
      {
        key: '7',
        id: 7,
        name: '雅康高速隧道群项目',
        code: 'PRJ2024007',
        type: '隧道工程',
        department: '隧道工程部',
        owner: '四川省交通厅',
        manager: userInfo.name,
        creator: '郑工',
        createTime: '2024-02-25',
      },
      {
        key: '8',
        id: 8,
        name: '长江大桥加固工程',
        code: 'PRJ2024008',
        type: '桥梁工程',
        department: '桥梁工程部',
        owner: '武汉市交通局',
        manager: '孙工',
        creator: userInfo.name,
        createTime: '2024-03-01',
      }
    ];

    // 生成其他随机数据
    const randomProjects: Project[] = Array.from({ length: 12 }, (_, index) => ({
      key: String(index + 9),
      id: index + 9,
      name: `${['长江', '黄河', '珠江', '松花江'][index % 4]}${['大桥', '隧道', '高速', '快速路'][index % 4]}${['维修', '改造', '建设', '扩建'][index % 4]}工程`,
      code: `PRJ${String(2024009 + index).padStart(7, '0')}`,
      type: ['道路工程', '桥梁工程', '隧道工程'][index % 3],
      department: `${['工程', '养护', '建设', '智能化'][index % 4]}部`,
      owner: `${['北京', '上海', '广州', '深圳'][index % 4]}市交通局`,
      manager: index % 2 === 0 ? userInfo.name : `${['李', '王', '张', '赵'][index % 4]}工`,
      creator: index % 2 === 0 ? `${['陈', '林', '黄', '周'][index % 4]}工` : userInfo.name,
      createTime: `2024-${String((index % 3) + 1).padStart(2, '0')}-${String((index % 28) + 1).padStart(2, '0')}`,
    }));

    const allProjects = [...sampleProjects, ...randomProjects];
    setProjects(allProjects);
    setFilteredProjects(allProjects);
  }, [userInfo.name]);

  // 搜索和筛选逻辑
  useEffect(() => {
    let result = [...projects];

    // 搜索文本过滤
    if (searchText) {
      result = result.filter(
        project =>
          project.name.toLowerCase().includes(searchText.toLowerCase()) ||
          project.code.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    // 项目类型滤
    if (selectedType) {
      result = result.filter(project => project.type === selectedType);
    }

    // 部门过滤
    if (selectedDepartment) {
      result = result.filter(project => project.department === selectedDepartment);
    }

    setFilteredProjects(result);
  }, [searchText, selectedType, selectedDepartment, projects]);

  const columns: ColumnsType<Project> = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 50,
      fixed: 'left',
      align: 'center',
      className: 'column-center',
    },
    {
      title: '项目名称',
      dataIndex: 'name',
      ellipsis: true,
      width: 120,
      fixed: 'left',
      align: 'center',
      className: 'column-center',
      render: (text: string, record: Project) => (
        <Button 
          type="link" 
          onClick={() => navigate(`/projects/${record.key}/engineering`)}
        >
          {text}
        </Button>
      ),
    },
    {
      title: '项目编码',
      dataIndex: 'code',
      width: 100,
      align: 'center',
      className: 'column-center',
    },
    {
      title: '项目类型',
      dataIndex: 'type',
      width: 100,
      align: 'center',
      className: 'column-center',
    },
    {
      title: '生产部',
      dataIndex: 'department',
      width: 100,
      align: 'center',
      className: 'column-center',
    },
    {
      title: '项目业主',
      dataIndex: 'owner',
      width: 100,
      align: 'center',
      className: 'column-center',
    },
    {
      title: '项目负责人',
      dataIndex: 'manager',
      width: 100,
      align: 'center',
      className: 'column-center',
    },
    {
      title: '项目创建人',
      dataIndex: 'creator',
      width: 100,
      align: 'center',
      className: 'column-center',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: 120,
      align: 'center',
      className: 'column-center',
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 120,
      align: 'center',
      className: 'column-center',
      render: (_, record) => (
        <Space size="small">
          <Button 
            type="link" 
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            修改
          </Button>
          <Popconfirm
            title="确定删除此项目吗？"
            onConfirm={() => handleDelete(record)}
            okText="确定"
            cancelText="取消"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // 处理搜索
  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  // 处理类型选择
  const handleTypeChange = (value: string) => {
    setSelectedType(value);
  };

  // 处理部门选择
  const handleDepartmentChange = (value: string) => {
    setSelectedDepartment(value);
  };

  const handleEdit = (record: Project) => {
    setEditingProject(record);
    editForm.setFieldsValue({
      name: record.name,
      type: record.type,
      department: record.department,
      owner: record.owner,
      manager: record.manager,
    });
    setEditModalVisible(true);
  };

  const handleEditSubmit = async () => {
    try {
      const values = await editForm.validateFields();
      // 这里添加实际的更新逻辑
      const updatedProjects = projects.map(project => {
        if (project.key === editingProject?.key) {
          return {
            ...project,
            ...values,
          };
        }
        return project;
      });
      setProjects(updatedProjects);
      message.success('修改成功');
      setEditModalVisible(false);
    } catch (error) {
      message.error('请检查表单填写是否正确');
    }
  };

  const handleDelete = (record: Project) => {
    console.log('删除项目:', record);
    message.success('删除成功');
    setProjects(projects.filter(item => item.key !== record.key));
  };

  // 检查项目编码唯一性
  const checkCodeUnique = async (rule: any, value: string) => {
    if (!value) {
      return Promise.reject('请输入项目编码');
    }
    // 检查是否已存在相同编码
    const exists = projects.some(project => project.code === value);
    if (exists) {
      return Promise.reject('项目编码已存在');
    }
    return Promise.resolve();
  };

  // 处理新建项目
  const handleAdd = () => {
    addForm.resetFields();
    setAddModalVisible(true);
  };

  const handleAddSubmit = async () => {
    try {
      const values = await addForm.validateFields();
      const newProject: Project = {
        key: String(projects.length + 1),
        id: projects.length + 1,
        creator: userInfo.name,
        createTime: new Date().toLocaleDateString('zh-CN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).replace(/\//g, '-'),
        ...values,
      };
      setProjects([...projects, newProject]);
      message.success('创建成功');
      setAddModalVisible(false);
    } catch (error) {
      message.error('请检查表单填写是否正确');
    }
  };

  // 处理页码变化
  const handlePageChange = (page: number, size?: number) => {
    setCurrentPage(page);
    if (size) {
      setPageSize(size);
    }
  };

  // 处理每页显示数量变化
  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
    setCurrentPage(1); // 重置到第一页
  };

  // 修改构件数据
  const [components] = useState<Component3D[]>([
    {
      id: 'p1',
      name: '桥梁库',
      thumbnail: thumbnails.bridge1,
      group: 'personal',
      category: '桥梁'
    },
    {
      id: 'p2',
      name: '隧道库',
      thumbnail: thumbnails.tunnel1,
      group: 'personal',
      category: '隧道'
    },
    {
      id: 'p3',
      name: '道路库',
      thumbnail: thumbnails.road1,
      group: 'personal',
      category: '道路'
    },
    {
      id: 'p4',
      name: '交安库',
      thumbnail: thumbnails.custom1,
      group: 'personal',
      category: '交安'
    },
    {
      id: 'p5',
      name: '涵洞',
      thumbnail: thumbnails.custom1,
      group: 'personal',
      category: '涵洞'
    },
    {
      id: 'p6',
      name: '市政管线库',
      thumbnail: thumbnails.custom1,
      group: 'personal',
      category: '市政管线'
    },
    {
      id: 'p7',
      name: '道路机电库',
      thumbnail: thumbnails.custom1,
      group: 'personal',
      category: '道路机电'
    },
    {
      id: 'p8',
      name: '个人自义',
      thumbnail: thumbnails.custom1,
      group: 'personal',
      category: '自定义'
    },

    // 企业库
    {
      id: 'e1',
      name: '桥梁库',
      thumbnail: thumbnails.bridge1,
      group: 'enterprise',
      category: '桥梁'
    },
    {
      id: 'e2',
      name: '隧道库',
      thumbnail: thumbnails.tunnel1,
      group: 'enterprise',
      category: '隧道'
    },
    {
      id: 'e3',
      name: '道路库',
      thumbnail: thumbnails.road1,
      group: 'enterprise',
      category: '道路'
    },
    {
      id: 'e4',
      name: '安库',
      thumbnail: thumbnails.custom1,
      group: 'enterprise',
      category: '交安'
    },
    {
      id: 'e5',
      name: '涵洞库',
      thumbnail: thumbnails.custom1,
      group: 'enterprise',
      category: '涵洞'
    },
    {
      id: 'e6',
      name: '市政管线库',
      thumbnail: thumbnails.custom1,
      group: 'enterprise',
      category: '市政管线'
    },
    {
      id: 'e7',
      name: '道路机电库',
      thumbnail: thumbnails.custom1,
      group: 'enterprise',
      category: '道路机电'
    },

    // 公共库
    {
      id: 'pub1',
      name: '桥梁库',
      thumbnail: thumbnails.bridge1,
      group: 'public',
      category: '桥梁'
    },
    {
      id: 'pub2',
      name: '隧道库',
      thumbnail: thumbnails.tunnel1,
      group: 'public',
      category: '隧道'
    },
    {
      id: 'pub3',
      name: '道路库',
      thumbnail: thumbnails.road1,
      group: 'public',
      category: '道路'
    },
    {
      id: 'pub4',
      name: '交安库',
      thumbnail: thumbnails.custom1,
      group: 'public',
      category: '交安'
    },
    {
      id: 'pub5',
      name: '涵洞库',
      thumbnail: thumbnails.custom1,
      group: 'public',
      category: '涵洞'
    },
    {
      id: 'pub6',
      name: '市政管线库',
      thumbnail: thumbnails.custom1,
      group: 'public',
      category: '市政管线'
    },
    {
      id: 'pub7',
      name: '道路机电库',
      thumbnail: thumbnails.custom1,
      group: 'public',
      category: '道路机电'
    },
  ]);

  // 添加材质相关的状态
  const [materials, setMaterials] = useState<Material[]>([
    {
      id: 'm1',
      name: '混凝土材质',
      thumbnail: thumbnails.material1,
      category: '基础材质',
      type: 'my',
      baseSettings: {
        color: '#000000',
        ambientColor: '#1a1a1a',
        opacity: 1,
      },
      textureSettings: {
        textureName: '混凝土纹理',
        size: { x: 1, y: 1 },
        offset: { x: 0, y: 0 },
        mappingType: 'parametric',
      },
    },
    // ... 其他材质数据
  ]);

  // 添加默认材质配置
  const defaultMaterial: Material = {
    id: '',
    name: '',
    thumbnail: thumbnails.material1,
    category: '基础材质',
    type: 'my',
    baseSettings: {
      color: '#000000',
      ambientColor: '#1a1a1a',
      opacity: 1,
    },
    textureSettings: {
      textureName: '',
      size: { x: 1, y: 1 },
      offset: { x: 0, y: 0 },
      mappingType: 'parametric',
    },
  };

  // 保留这一个 handleMaterialClick 函数定义
  const handleMaterialClick = (material: Material | null) => {
    setCurrentMaterial(material || { ...defaultMaterial, id: `m${materials.length + 1}` });
    setMaterialDetailVisible(true);
  };

  // 添加映射方式变更处理函数
  const handleMappingTypeChange = (value: Material['textureSettings']['mappingType']) => {
    if (!currentMaterial) return;
    const newMaterial = { ...currentMaterial };
    newMaterial.textureSettings.mappingType = value;
    setCurrentMaterial(newMaterial);
  };

  // 修改材质详情弹窗组件中的材质更新���理
  const handleMaterialUpdate = () => {
    if (!currentMaterial) return;

    if (currentMaterial.id === '') {
      // 新建材质
      setMaterials(prev => [...prev, { ...currentMaterial, id: `m${materials.length + 1}` }]);
      message.success('新建材质成功');
    } else {
      // 更新现有材质
      setMaterials(prev =>
        prev.map(m => (m.id === currentMaterial.id ? currentMaterial : m))
      );
      message.success('更新材质成功');
    }
    setMaterialDetailVisible(false);
  };

  // 修改材质详情弹窗组件
  const MaterialDetailModal: React.FC<{
    material: Material | null;
    visible: boolean;
    onClose: () => void;
  }> = ({ material, visible, onClose }) => {
    const [isEditing, setIsEditing] = useState(material?.id === '');  // 新建时默认为编辑状态
    const [currentMaterial, setCurrentMaterial] = useState<Material | null>(material);

    // 处理材质更新
    const handleMaterialUpdate = () => {
      if (!currentMaterial) return;

      if (currentMaterial.id === '') {
        // 新建材质
        setMaterials(prev => [...prev, { ...currentMaterial, id: `m${materials.length + 1}` }]);
        message.success('新建材质成功');
      } else {
        // 更新现有材质
        setMaterials(prev =>
          prev.map(m => (m.id === currentMaterial.id ? currentMaterial : m))
        );
        message.success('更新材质成功');
      }
      setIsEditing(false);
      onClose();
    };

    // 处理各种属性变更
    const handlePropertyChange = (property: string, value: any) => {
      if (!currentMaterial) return;

      const newMaterial = { ...currentMaterial };
      if (property.includes('.')) {
        const [section, field] = property.split('.');
        (newMaterial as any)[section][field] = value;
      } else {
        (newMaterial as any)[property] = value;
      }
      setCurrentMaterial(newMaterial);
    };

    if (!material) return null;

    return (
      <Modal
        title={material.id ? '材质详情' : '新建材质'}
        open={visible}
        onCancel={onClose}
        footer={[
          <Button key="cancel" onClick={onClose}>
            取消
          </Button>,
          isEditing ? (
            <Button key="save" type="primary" onClick={handleMaterialUpdate}>
              完成
            </Button>
          ) : (
            <Button key="edit" type="primary" onClick={() => setIsEditing(true)}>
              编辑
            </Button>
          ),
        ]}
        width={800}
        className="material-detail-modal"
      >
        <div className="material-detail-content">
          {/* 预览图 */}
          <div className="material-preview">
            <img src={material.thumbnail} alt={material.name} />
          </div>

          {/* 基础设置 */}
          <div className="setting-section">
            <h3>基础设置</h3>
            <Form layout="vertical">
              <Form.Item label="材质名称">
                <Input
                  value={currentMaterial?.name}
                  onChange={e => handlePropertyChange('name', e.target.value)}
                  readOnly={!isEditing}
                />
              </Form.Item>
              <Form.Item label="颜色">
                <Input type="color" value={currentMaterial?.baseSettings.color} readOnly />
              </Form.Item>
              <Form.Item label="环境光颜色">
                <Input type="color" value={currentMaterial?.baseSettings.ambientColor} readOnly />
              </Form.Item>
              <Form.Item label="透明度">
                <Slider
                  value={currentMaterial?.baseSettings.opacity}
                  min={0}
                  max={1}
                  step={0.01}
                  disabled={!isEditing}
                />
              </Form.Item>
            </Form>
          </div>

          {/* 贴图设置 */}
          <div className="setting-section">
            <h3>贴图设置</h3>
            <Form layout="vertical">
              <Form.Item label="贴图纹理">
                <Input value={currentMaterial?.textureSettings.textureName} readOnly />
              </Form.Item>
              <Form.Item label="贴图尺寸">
                <Input.Group compact>
                  <Input
                    style={{ width: '50%' }}
                    value={currentMaterial?.textureSettings.size.x}
                    addonBefore="X"
                    readOnly
                  />
                  <Input
                    style={{ width: '50%' }}
                    value={currentMaterial?.textureSettings.size.y}
                    addonBefore="Y"
                    readOnly
                  />
                </Input.Group>
              </Form.Item>
              <Form.Item label="贴图偏移">
                <Input.Group compact>
                  <Input
                    style={{ width: '50%' }}
                    value={currentMaterial?.textureSettings.offset.x}
                    addonBefore="X"
                    readOnly
                  />
                  <Input
                    style={{ width: '50%' }}
                    value={currentMaterial?.textureSettings.offset.y}
                    addonBefore="Y"
                    readOnly
                  />
                </Input.Group>
              </Form.Item>
              <Form.Item label="映射方式">
                <Select 
                  value={currentMaterial?.textureSettings.mappingType}
                  disabled={!isEditing}
                  onChange={handleMappingTypeChange}
                >
                  {mappingOptions.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className="modal-header-actions">
          <Button 
            type="primary"
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ? '完成' : '编辑'}
          </Button>
        </div>
      </Modal>
    );
  };

  // 修改材质库部分的新建按钮点击事件
  <Button 
    type="primary" 
    icon={<PlusOutlined />}
    onClick={() => handleMaterialClick(null)}  // 传入 null 表示新建
  >
    新建材质
  </Button>

  // 修改折叠状态
  const [collapsedGroups, setCollapsedGroups] = useState({
    recent: false, // 添加最近打开项目的折叠状态
    created: false,
    participated: false,
    components: false,
    materials: false,
    templates: false,
    apps: false,
  });

  // 处理叠切换
  const toggleCollapse = (group: keyof typeof collapsedGroups) => {
    setCollapsedGroups(prev => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  // 渲染构件库内容
  const renderComponentLibrary = () => {
    const groups = {
      personal: { title: '个人库', items: [] as Component3D[] },
      enterprise: { title: '企业库', items: [] as Component3D[] },
      public: { title: '公共库', items: [] as Component3D[] },
    };

    // 按分组整理构件
    components.forEach(component => {
      groups[component.group].items.push(component);
    });

    return (
      <div className="component-library">
        {Object.entries(groups).map(([key, group]) => (
          <div key={key} className="component-group">
            <div className="group-header">
              <h3>{group.title}</h3>
              <span className="group-count">{group.items.length}个构件</span>
            </div>
            <div className="component-grid">
              {group.items.map(component => (
                <Card
                  key={component.id}
                  hoverable
                  cover={<img alt={component.name} src={component.thumbnail} />}
                  className="component-card"
                  onClick={() => navigate(`/components/${component.id}`)}
                >
                  <Card.Meta
                    title={component.name}
                    //description={component.type}
                  />
                </Card>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  // ���加模板操作的状态
  const [templateModalVisible, setTemplateModalVisible] = useState(false);
  const [templateForm] = Form.useForm();
  const [editingTemplate, setEditingTemplate] = useState<Template | null>(null);

  // 处理新模板
  const handleAddTemplate = () => {
    templateForm.resetFields();
    setEditingTemplate(null);
    setTemplateModalVisible(true);
  };

  // 处理模板编辑
  const handleEditTemplate = (template: Template) => {
    setEditingTemplate(template);
    templateForm.setFieldsValue(template);
    setTemplateModalVisible(true);
  };

  // 处理模板删除
  const handleDeleteTemplate = (template: Template) => {
    Modal.confirm({
      title: '确认删除',
      content: `确定要删除模板"${template.name}"吗？`,
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        setTemplates(prev => prev.filter(t => t.id !== template.id));
        message.success('删除成功');
      },
    });
  };

  // 处理模板表单提交
  const handleTemplateSubmit = async () => {
    try {
      const values = await templateForm.validateFields();
      if (editingTemplate) {
        // 编辑现有模板
        setTemplates(prev =>
          prev.map(t =>
            t.id === editingTemplate.id ? { ...t, ...values } : t
          )
        );
        message.success('修改成功');
      } else {
        // 创建新模板
        const newTemplate: Template = {
          id: `t${templates.length + 1}`,
          category: 'my',
          ...values,
        };
        setTemplates(prev => [...prev, newTemplate]);
        message.success('创建成功');
      }
      setTemplateModalVisible(false);
    } catch (error) {
      message.error('请检查表单填写是否正确');
    }
  };

  // 获取模板卡片的右键菜单
  const getTemplateContextMenu = (template: Template) => ({
    items: [
      {
        key: 'edit',
        icon: <EditOutlined />,
        label: '修改',
        onClick: () => handleEditTemplate(template),
      },
      {
        key: 'delete',
        icon: <DeleteOutlined />,
        label: '删除',
        danger: true,
        onClick: () => handleDeleteTemplate(template),
      },
    ],
  });

  // 修改应用数据
  const [trafficApps] = useState<TrafficApp[]>([
    {
      id: 'app1',
      name: '路线优化设计助手',
      icon: icons['route-optimizer'],
      category: '路线设计',
      description: '基于AI的路线方案优化工具，支持多方案对比和分析。',
      version: 'v2.1.0',
      developer: '智能交通研究院',
      price: 2999,
      features: [
        '智能路线方案生成',
        '多方案对比分析',
        '施工成本估算',
        '环境影响评估',
        '实时协同设计'
      ],
      screenshots: [
        generatePlaceholderImage('路线优化-主界面', 800, 450),
        generatePlaceholderImage('路线分析', 800, 450),
        generatePlaceholderImage('方���对比', 800, 450),
      ],
      subscribed: true
    },
    {
      id: 'app2',
      name: '桥梁结构检测工具',
      icon: icons['bridge-inspector'],
      category: '桥梁检测',
      description: '桥梁结构健康监测和分析系统，支持实时数据采集和预警。',
      version: 'v1.5.2',
      developer: '结构安全研究所',
      price: 1999,
      features: [
        '结构健康监测',
        '实时数据采集',
        '预警分析',
        '检测报告生成'
      ],
      screenshots: [
        generatePlaceholderImage('桥梁检测-主界面', 800, 450),
        generatePlaceholderImage('结构健康监测', 800, 450),
        generatePlaceholderImage('预警分析', 800, 450),
      ],
      subscribed: true
    },
    {
      id: 'app3',
      name: '隧道施工模拟器',
      icon: icons['tunnel-simulator'],
      category: '隧道施工',
      description: '隧道施工过程的3D模拟和分析工具，支持施工方案优化。',
      version: 'v3.0.1',
      developer: '地下工程研究中心',
      price: 3999,
      features: [
        '隧道施工模拟',
        '施工方案优化',
        '风险评估',
        '施工进度监控'
      ],
      screenshots: [
        generatePlaceholderImage('隧道施工模拟-主界面', 800, 450),
        generatePlaceholderImage('隧道施工模拟', 800, 450),
        generatePlaceholderImage('风险评估', 800, 450),
      ],
      subscribed: false
    },
    {
      id: 'app4',
      name: '交通流量分析系统',
      icon: icons['traffic-analyzer'],
      category: '交通分析',
      description: '基于大数据的交通流量分析和预测系统。',
      version: 'v2.2.3',
      developer: '智慧交通实验室',
      price: 2499,
      features: [
        '交通流量分析',
        '交通预测',
        '交通拥堵分析',
        '交通规划优化'
      ],
      screenshots: [
        generatePlaceholderImage('交通流量分析-主界面', 800, 450),
        generatePlaceholderImage('交通流量分析', 800, 450),
        generatePlaceholderImage('交通拥堵分析', 800, 450),
      ],
      subscribed: true
    }
  ]);

  // 添加应用详情弹窗状态
  const [appDetailVisible, setAppDetailVisible] = useState(false);
  const [currentApp, setCurrentApp] = useState<TrafficApp | null>(null);

  // 处理应用点击
  const handleAppClick = (app: TrafficApp) => {
    setCurrentApp(app);
    setAppDetailVisible(true);
  };

  // 修改应用详情弹窗组件
  const AppDetailModal: React.FC<{
    app: TrafficApp | null;
    visible: boolean;
    onClose: () => void;
  }> = ({ app, visible, onClose }) => {
    // 添加加载状态
    const [isLoading, setIsLoading] = useState(false);

    if (!app) return null;

    // 处理加载/卸载
    const handleToggleLoad = () => {
      setIsLoading(true);
      // 模拟加载/卸载过程
      setTimeout(() => {
        setIsLoading(false);
        message.success(app.loaded ? '应用已卸载' : '应用已加载');
        if (app.loaded) {
          app.loaded = false;
        } else {
          app.loaded = true;
        }
      }, 1000);
    };

    return (
      <Modal
        title={null}
        open={visible}
        onCancel={onClose}
        footer={null}
        width={800}
        className="app-detail-modal"
      >
        <div className="app-detail-header">
          <div className="app-basic-info">
            <img src={app.icon} alt={app.name} className="app-icon" />
            <div className="app-info">
              <h2 className="app-name">{app.name}</h2>
              <div className="app-meta">
                <span>版本：{app.version}</span>
                <span>开发者：{app.developer}</span>
              </div>
            </div>
          </div>
          <div className="app-price">
            {app.subscribed && (
              <Button
                type={app.loaded ? 'default' : 'primary'}
                loading={isLoading}
                onClick={handleToggleLoad}
                icon={app.loaded ? <PoweroffOutlined /> : <ThunderboltOutlined />}
              >
                {app.loaded ? '卸载' : '加载'}
              </Button>
            )}
            {!app.subscribed && (
              <>
                <span className="price-label">价格：</span>
                <span className="price-value">￥{app.price}</span>
              </>
            )}
          </div>
        </div>

        <div className="app-screenshots">
          <Carousel autoplay>
            {app.screenshots.map((screenshot, index) => (
              <div key={index} className="screenshot-item">
                <img src={screenshot} alt={`${app.name} 截图 ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </div>

        <div className="app-description">
          <h3>产品介绍</h3>
          <p>{app.description}</p>
        </div>

        <div className="app-features">
          <h3>主要功能</h3>
          <ul>
            {app.features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </div>

        <div className="app-actions">
          {app.subscribed ? (
            <Button type="primary" onClick={onClose}>
              关闭
            </Button>
          ) : (
            <Button type="primary" onClick={() => message.info('购买功能开发中')}>
              立即购买
            </Button>
          )}
        </div>
      </Modal>
    );
  };

  // 添加材质详情弹窗状态
  const [materialDetailVisible, setMaterialDetailVisible] = useState(false);
  const [currentMaterial, setCurrentMaterial] = useState<Material | null>(null);

  // 映射方式选项
  const mappingOptions = [
    { label: '参数化', value: 'parametric' },
    { label: '高程垂挂', value: 'heightVertical' },
    { label: '平面', value: 'planar' },
    { label: '定向垂挂', value: 'orientedVertical' },
    { label: '立方体', value: 'cubic' },
    { label: '球形', value: 'spherical' },
    { label: '圆柱形', value: 'cylindrical' },
    { label: '实体', value: 'solid' },
  ];

  // 添加最近打开项目的状态
  const [recentProjects, setRecentProjects] = useState<RecentProject[]>([]);

  // 在 useEffect 中添加最近打开项目的模拟数据
  useEffect(() => {
    // 从所有项目中随机选择5个作为最近打开的项目
    const recent = projects
      .slice(0, 5)
      .map(project => ({
        ...project,
        lastOpenTime: new Date(Date.now() - Math.random() * 7 * 24 * 60 * 60 * 1000)
          .toLocaleDateString('zh-CN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
          })
      }))
      .sort((a, b) => new Date(b.lastOpenTime).getTime() - new Date(a.lastOpenTime).getTime());
    
    setRecentProjects(recent);
  }, [projects]);

  // 添加视图模式状态
  const [viewMode, setViewMode] = useState<'table' | 'card'>('table');

  // 添加项目卡片渲染函数
  const renderProjectCard = (project: Project | RecentProject) => (
    <Card
      key={project.key}
      hoverable
      className="project-card"
      actions={[
        <Button 
          type="link" 
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/projects/${project.key}/engineering`);
          }}
        >
          打开
        </Button>,
        <Button 
          type="link" 
          onClick={(e) => {
            e.stopPropagation();
            handleEdit(project as Project);
          }}
        >
          编辑
        </Button>,
        <Popconfirm
          title="确定删除此项目吗？"
          onConfirm={(e) => {
            e?.stopPropagation();
            handleDelete(project as Project);
          }}
          okText="确定"
          cancelText="取消"
        >
          <Button 
            type="link" 
            danger 
            onClick={e => e.stopPropagation()}
          >
            删除
          </Button>
        </Popconfirm>
      ]}
    >
      <Card.Meta
        avatar={<ProjectOutlined style={{ fontSize: '24px', color: '#1890ff' }} />}
        title={project.name}
        description={
          <div className="project-card-content">
            <div className="project-info">
              <p><strong>编码：</strong>{project.code}</p>
              <p><strong>类型：</strong>{project.type}</p>
              <p><strong>部门：</strong>{project.department}</p>
              <p><strong>负责人：</strong>{project.manager}</p>
            </div>
            {'lastOpenTime' in project && (
              <div className="last-open-time">
                <p><strong>最后打开：</strong>{project.lastOpenTime}</p>
              </div>
            )}
          </div>
        }
      />
    </Card>
  );

  // 添加视图切换按钮组件
  const ViewToggle = () => (
    <Space className="view-toggle">
      <Button
        type={viewMode === 'table' ? 'primary' : 'default'}
        icon={<TableOutlined />}
        onClick={() => setViewMode('table')}
      >
        表格
      </Button>
      <Button
        type={viewMode === 'card' ? 'primary' : 'default'}
        icon={<AppstoreOutlined />}
        onClick={() => setViewMode('card')}
      >
        卡片
      </Button>
    </Space>
  );

  // 修改渲染内容函数，添加视图切换功能
  const renderProjectList = (projects: Project[] | RecentProject[], showPagination = true) => {
    if (viewMode === 'card') {
      return (
        <div className="project-card-grid">
          {projects.map(project => renderProjectCard(project))}
        </div>
      );
    }

    return (
      <Table
        columns={columns}
        dataSource={projects}
        loading={loading}
        scroll={{ x: 1040 }}
        pagination={showPagination ? {
          pageSize,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条`,
        } : false}
        size="small"
      />
    );
  };

  // 修改 renderContent 中的表格渲染部分
  const renderContent = (): React.ReactNode => {
    switch (selectedNav) {
      case 'all':
        return (
          <div className="all-content">
            {/* 最近打开的项目 */}
            <div className="content-group">
              <div className="group-header" onClick={() => toggleCollapse('recent')}>
                <div className="header-left">
                  {collapsedGroups.recent ? <RightOutlined /> : <DownOutlined />}
                  <h3>最近打开的项目</h3>
                  <span className="group-count">
                    {recentProjects.length}个项目
                  </span>
                </div>
              </div>
              {!collapsedGroups.recent && (
                <Table
                  columns={[
                    ...columns.filter(col => col.key !== 'action'),
                    {
                      title: '最后打开时间',
                      dataIndex: 'lastOpenTime',
                      key: 'lastOpenTime',
                      width: 160,
                      align: 'center',
                    },
                    {
                      title: '操作',
                      key: 'action',
                      fixed: 'right',
                      width: 120,
                      align: 'center',
                      render: (_, record) => (
                        <Space size="small">
                          <Button 
                            type="link"
                            onClick={() => navigate(`/projects/${record.key}/engineering`)}
                          >
                            打开
                          </Button>
                        </Space>
                      ),
                    }
                  ]}
                  dataSource={recentProjects}
                  loading={loading}
                  scroll={{ x: 1040 }}
                  pagination={false}
                  size="small"
                />
              )}
            </div>

            {/* 我创建的项目 */}
            <div className="content-group">
              <div className="group-header" onClick={() => toggleCollapse('created')}>
                <div className="header-left">
                  {collapsedGroups.created ? <RightOutlined /> : <DownOutlined />}
                  <h3>我的项目</h3>
                  <span className="group-count">
                    {projects.filter(p => p.creator === userInfo.name).length}个项目
                  </span>
                </div>
              </div>
              {!collapsedGroups.created && (
                <Table
                  columns={columns}
                  dataSource={projects.filter(p => p.creator === userInfo.name)}
                  loading={loading}
                  scroll={{ x: 1040 }}
                  pagination={false}
                  size="small"
                />
              )}
            </div>

            {/* 我参与的项目 */}
            <div className="content-group">
              <div className="group-header" onClick={() => toggleCollapse('participated')}>
                <div className="header-left">
                  {collapsedGroups.participated ? <RightOutlined /> : <DownOutlined />}
                  <h3>参与的项目</h3>
                  <span className="group-count">
                    {projects.filter(p => p.manager === userInfo.name).length}个项目
                  </span>
                </div>
              </div>
              {!collapsedGroups.participated && (
                <Table
                  columns={columns}
                  dataSource={projects.filter(p => p.manager === userInfo.name)}
                  loading={loading}
                  scroll={{ x: 1040 }}
                  pagination={false}
                  size="small"
                />
              )}
            </div>

            {/* 我的材质库概览 */}
            <div className="content-group">
              <div className="group-header" onClick={() => toggleCollapse('materials')}>
                <div className="header-left">
                  {collapsedGroups.materials ? <RightOutlined /> : <DownOutlined />}
                  <h3>材质库</h3>
                  <span className="group-count">
                    {materials.filter(m => m.type === 'my').length}个材质
                  </span>
                </div>
              </div>
              {!collapsedGroups.materials && (
                <div className="preview-grid">
                  {materials
                    .filter(m => m.type === 'my')
                    .slice(0, 4)
                    .map(material => (
                      <Card
                        key={material.id}
                        hoverable
                        cover={<img alt={material.name} src={material.thumbnail} />}
                        className="preview-card"
                        onClick={() => handleMaterialClick(material)}
                      >
                        <Card.Meta
                          title={material.name}
                          description={material.category}
                        />
                      </Card>
                    ))}
                </div>
              )}
            </div>

            {/* 我的组件模板概览 */}
            <div className="content-group">
              <div className="group-header" onClick={() => toggleCollapse('templates')}>
                <div className="header-left">
                  {collapsedGroups.templates ? <RightOutlined /> : <DownOutlined />}
                  <h3>组件模板</h3>
                  <span className="group-count">
                    {templates.filter(t => t.category === 'my').length}个模板
                  </span>
                </div>
              </div>
              {!collapsedGroups.templates && (
                <div className="preview-grid">
                  {templates
                    .filter(t => t.category === 'my')
                    .slice(0, 4)
                    .map(template => (
                      <Card
                        key={template.id}
                        hoverable
                        cover={<img alt={template.name} src={template.thumbnail} />}
                        className="preview-card"
                        onClick={() => navigate(`/templates/${template.id}`)}
                      >
                        <Card.Meta
                          title={template.name}
                          description={
                            <div>
                              <div className="template-type">{template.type}</div>
                              <div className="template-description">{template.description}</div>
                            </div>
                          }
                        />
                      </Card>
                    ))}
                </div>
              )}
            </div>

            {/* 我的应用概览 */}
            <div className="content-group">
              <div className="group-header" onClick={() => toggleCollapse('apps')}>
                <div className="header-left">
                  {collapsedGroups.apps ? <RightOutlined /> : <DownOutlined />}
                  <h3>应用市场</h3>
                  <span className="group-count">
                    {trafficApps.filter(app => app.subscribed).length}个订阅
                  </span>
                </div>
              </div>
              {!collapsedGroups.apps && (
                <div className="preview-grid">
                  {trafficApps
                    .filter(app => app.subscribed)
                    .map(app => (
                      <Card
                        key={app.id}
                        hoverable
                        className="app-card"
                        onClick={() => handleAppClick(app)}
                      >
                        <div className="app-header">
                          <img src={app.icon} alt={app.name} className="app-icon" />
                          <div className="app-info">
                            <div className="app-name">{app.name}</div>
                            <div className="app-category">{app.category}</div>
                          </div>
                        </div>
                        <div className="app-description">{app.description}</div>
                        <div className="app-footer">
                          <span className="app-version">{app.version}</span>
                          <span className="app-developer">{app.developer}</span>
                        </div>
                      </Card>
                    ))}
                </div>
              )}
            </div>
          </div>
        );
      case 'created':
        return (
          <>
            <div className="content-header">
              <ViewToggle />
            </div>
            {renderProjectList(filteredProjects.filter(p => p.creator === userInfo.name))}
          </>
        );
      case 'participated':
        return (
          <>
            <div className="content-header">
              <ViewToggle />
            </div>
            {renderProjectList(filteredProjects.filter(p => p.manager === userInfo.name))}
          </>
        );
      case 'components':
        return renderComponentLibrary();
      case 'materials':
        return (
          <div className="material-container">
            {/* 我的材��� */}
            <div className="material-section">
              <div className="section-header">
                <div className="header-left">
                  <h3>我的材质</h3>
                  <span className="section-count">
                    {materials.filter(m => m.type === 'my').length}个材质
                  </span>
                </div>
                <Button 
                  type="primary" 
                  icon={<PlusOutlined />}
                  onClick={() => handleMaterialClick(null)}  // 传入 null 表示新建
                >
                  新建材质
                </Button>
              </div>
              <div className="material-grid">
                {materials
                  .filter(m => m.type === 'my')
                  .map(material => (
                    <Card
                      key={material.id}
                      hoverable
                      cover={<img alt={material.name} src={material.thumbnail} />}
                      className="material-card"
                      onClick={() => handleMaterialClick(material)}
                    >
                      <Card.Meta
                        title={material.name}
                        description={
                          <div className="material-info">
                            <span className="material-category">{material.category}</span>
                          </div>
                        }
                      />
                    </Card>
                  ))}
              </div>
            </div>

            {/* 平台材质 */}
            <div className="material-section">
              <div className="section-header">
                <div className="header-left">
                  <h3>平台材质</h3>
                  <span className="section-count">
                    {materials.filter(m => m.type === 'platform').length}个材质
                  </span>
                </div>
              </div>
              <div className="material-grid">
                {materials
                  .filter(m => m.type === 'platform')
                  .map(material => (
                    <Card
                      key={material.id}
                      hoverable
                      cover={<img alt={material.name} src={material.thumbnail} />}
                      className="material-card"
                      onClick={() => handleMaterialClick(material)}
                    >
                      <Card.Meta
                        title={material.name}
                        description={
                          <div className="material-info">
                            <span className="material-category">{material.category}</span>
                          </div>
                        }
                      />
                    </Card>
                  ))}
              </div>
            </div>
          </div>
        );
      case 'templates':
        return (
          <div className="template-container">
            {/* 我的模板 */}
            <div className="template-section">
              <div className="section-header">
                <div className="header-left">
                  <h3>我的模板</h3>
                  <span className="section-count">
                    {templates.filter(t => t.category === 'my').length}个模板
                  </span>
                </div>
                <Button 
                  type="primary" 
                  icon={<PlusOutlined />} 
                  onClick={handleAddTemplate}
                >
                  新建模板
                </Button>
              </div>
              <div className="template-grid">
                {templates
                  .filter(t => t.category === 'my')
                  .map(template => (
                    <Dropdown
                      key={template.id}
                      menu={getTemplateContextMenu(template)}
                      trigger={['contextMenu']}
                    >
                      <Card
                        hoverable
                        cover={<img alt={template.name} src={template.thumbnail} />}
                        className="template-card"
                        onClick={() => navigate(`/templates/${template.id}`)}
                      >
                        <Card.Meta
                          title={template.name}
                          description={
                            <div>
                              <div className="template-type">{template.type}</div>
                              <div className="template-description">{template.description}</div>
                            </div>
                          }
                        />
                      </Card>
                    </Dropdown>
                  ))}
              </div>
            </div>

            {/* 市场模板 */}
            <div className="template-section">
              <div className="section-header">
                <h3>市场模板</h3>
                <span className="section-count">
                  {templates.filter(t => t.category === 'market').length}个模板
                </span>
              </div>
              <div className="template-grid">
                {templates
                  .filter(t => t.category === 'market')
                  .map(template => (
                    <Card
                      key={template.id}
                      hoverable
                      cover={<img alt={template.name} src={template.thumbnail} />}
                      className="template-card"
                      onClick={() => navigate(`/templates/${template.id}`)}
                    >
                      <Card.Meta
                        title={template.name}
                        description={
                          <div>
                            <div className="template-type">{template.type}</div>
                            <div className="template-description">{template.description}</div>
                          </div>
                        }
                      />
                    </Card>
                  ))}
              </div>
            </div>
          </div>
        );
      case 'appstore':
        return (
          <div className="appstore-container">
            {/* 轮播图部分 */}
            <div className="banner-section">
              <Carousel autoplay effect="fade">
                {[
                  {
                    image: generatePlaceholderImage('新品发布：智能路线设计助手3.0', 1200, 400),
                    title: '智能路线设计助手3.0',
                    desc: '全新AI算法，设计效率提升300%'
                  },
                  {
                    image: generatePlaceholderImage('重磅更新：BIM协同设计平台', 1200, 400),
                    title: 'BIM协同设计平台',
                    desc: '多人实时协作，设计更高效'
                  },
                  {
                    image: generatePlaceholderImage('新功能：智能施工模拟', 1200, 400),
                    title: '智能施工模拟系统',
                    desc: '可视化施工模拟，提前发现问题'
                  }
                ].map((item, index) => (
                  <div key={index} className="banner-item">
                    <img src={item.image} alt={item.title} />
                    <div className="banner-content">
                      <h2>{item.title}</h2>
                      <p>{item.desc}</p>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>

            {/* 我的订阅部分 */}
            <div className="section-container">
              <div className="section-header">
                <h2>我的订阅</h2>
              </div>
              <div className="app-grid">
                {trafficApps
                  .filter(app => app.subscribed)
                  .map(app => (
                    <Card
                      key={app.id}
                      hoverable
                      className="app-card"
                      onClick={() => handleAppClick(app)}
                    >
                      <div className="app-header">
                        <img src={app.icon} alt={app.name} className="app-icon" />
                        <div className="app-info">
                          <div className="app-name">{app.name}</div>
                          <div className="app-category">{app.category}</div>
                        </div>
                      </div>
                      <div className="app-description">{app.description}</div>
                      <div className="app-footer">
                        <span className="app-version">{app.version}</span>
                        <span className="app-developer">{app.developer}</span>
                      </div>
                    </Card>
                  ))}
              </div>
            </div>

            {/* 最新上架部分 */}
            <div className="section-container">
              <div className="section-header">
                <h2>最新上架</h2>
                <Button 
                  type="link" 
                  onClick={() => navigate('/appstore/all')}
                >
                  查看全部 <RightOutlined />
                </Button>
              </div>
              <div className="app-grid">
                {trafficApps.slice(0, 4).map(app => (
                  <Card
                    key={app.id}
                    hoverable
                    className="app-card"
                    onClick={() => handleAppClick(app)}
                  >
                    <div className="app-header">
                      <img src={app.icon} alt={app.name} className="app-icon" />
                      <div className="app-info">
                        <div className="app-name">{app.name}</div>
                        <div className="app-category">{app.category}</div>
                      </div>
                    </div>
                    <div className="app-description">{app.description}</div>
                    <div className="app-footer">
                      <span className="app-version">{app.version}</span>
                      <span className="app-developer">{app.developer}</span>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          </div>
        );
      case 'recent':
        return (
          <>
            <div className="content-header">
              <ViewToggle />
            </div>
            {renderProjectList(recentProjects)}
          </>
        );
      default:
        return (
          <Table
            columns={columns}
            dataSource={filteredProjects}
            loading={loading}
            scroll={{ x: 1040 }}
            pagination={{
              pageSize,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共 ${total} 条`,
            }}
            size="small"
          />
        );
    }
  };

  // 添加云存储相关状态
  const [storageModalVisible, setStorageModalVisible] = useState(false);
  
  // 添加云存储套餐数据
  const storagePlans: StoragePlan[] = [
    {
      id: 'basic',
      name: '基础版',
      capacity: 100,
      price: 365,
      features: [
        '100GB 存储空间',
        '支持所有文件格式',
        '标准技术支持',
        '单文件最大 2GB'
      ]
    },
    {
      id: 'pro',
      name: '专业版',
      capacity: 500,
      price: 1499,
      recommended: true,
      features: [
        '500GB 存储空间',
        '支持所有文件格式',
        '优先技术支持',
        '单文件最大 10GB',
        '文件版本历史'
      ]
    },
    {
      id: 'enterprise',
      name: '企业版',
      capacity: 2000,
      price: 4999,
      features: [
        '2TB 存储空间',
        '支持所有文件格式',
        '24/7 专属技术支持',
        '单文件最大 50GB',
        '文件版本历史',
        '高级数据统计',
        '多人协作功能'
      ]
    }
  ];

  // 添加云存储购买弹窗组件
  const StorageModal: React.FC<{
    visible: boolean;
    onClose: () => void;
  }> = ({ visible, onClose }) => {
    const [selectedPlan, setSelectedPlan] = useState<string>('');

    const handlePurchase = () => {
      if (!selectedPlan) {
        message.warning('请选择存储套餐');
        return;
      }
      message.success('购买功能开发中');
      onClose();
    };

    return (
      <Modal
        title="云存储扩容"
        open={visible}
        onCancel={onClose}
        width={900}
        footer={[
          <Button key="cancel" onClick={onClose}>取消</Button>,
          <Button 
            key="purchase" 
            type="primary"
            disabled={!selectedPlan}
            onClick={handlePurchase}
          >
            立即购买
          </Button>
        ]}
      >
        <div className="storage-plans">
          <div className="plans-description">
            <h3>选择适合您的存储方案</h3>
            <p>为您的项目提供安全可靠的云存储服务</p>
          </div>
          <div className="plans-container">
            {storagePlans.map(plan => (
              <Card
                key={plan.id}
                className={`plan-card ${selectedPlan === plan.id ? 'selected' : ''} ${plan.recommended ? 'recommended' : ''}`}
                onClick={() => setSelectedPlan(plan.id)}
              >
                {plan.recommended && (
                  <div className="recommended-tag">推荐</div>
                )}
                <div className="plan-header">
                  <h3>{plan.name}</h3>
                  <div className="plan-price">
                    <span className="price">¥{plan.price}</span>
                    <span className="period">/年</span>
                  </div>
                  <div className="plan-capacity">
                    {plan.capacity >= 1000 ? 
                      `${plan.capacity / 1000}TB` : 
                      `${plan.capacity}GB`} 存储空间
                  </div>
                </div>
                <div className="plan-features">
                  {plan.features.map((feature, index) => (
                    <div key={index} className="feature-item">
                      <CheckOutlined /> {feature}
                    </div>
                  ))}
                </div>
              </Card>
            ))}
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <Layout className="project-list-layout">
      <Header className="header">
        <div className="header-left">
          <span className="logo">超融合BIM图形平台</span>
          <div className="divider" />
        </div>
        <div className="header-right">
          <Space>
            <ThemeSwitch />
            <Dropdown
              menu={{
                items: userMenuItems,
                onClick: handleMenuClick,
              }}
              placement="bottomRight"
            >
              <Button type="link" className="user-info">
                <UserOutlined /> {userInfo.name}
                <span className="user-role">({userInfo.role})</span>
              </Button>
            </Dropdown>
          </Space>
        </div>
      </Header>
      <Layout>
        <Sider 
          width={200} 
          collapsible 
          collapsed={collapsed}
          onCollapse={setCollapsed}
          className="project-sider"
          trigger={null}
        >
          <div className="sider-trigger" onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
          <Menu
            mode="inline"
            selectedKeys={[selectedNav]}
            items={navItems}
            onClick={handleNavSelect}
          />
        </Sider>
        <Layout>
          <div className="project-list">
            <Card>
              {/* 只在显示项目列表时显示搜索栏 */}
              {['created', 'participated'].includes(selectedNav) && (
                <div className="search-bar">
                  <Space size="middle">
                    <Search
                      placeholder="搜索项目名称/编码"
                      allowClear
                      enterButton={<SearchOutlined />}
                      onSearch={handleSearch}
                      style={{ width: 250 }}
                    />
                    <Select
                      placeholder="项目类型"
                      allowClear
                      style={{ width: 150 }}
                      onChange={handleTypeChange}
                      value={selectedType}
                    >
                      <Option value="道路工程">道路工程</Option>
                      <Option value="桥梁工程">桥梁工程</Option>
                      <Option value="隧道工程">隧道工程</Option>
                    </Select>
                    <Select
                      placeholder="生产部门"
                      allowClear
                      style={{ width: 150 }}
                      onChange={handleDepartmentChange}
                      value={selectedDepartment}
                    >
                      <Option value="部门1">部门1</Option>
                      <Option value="部门2">部门2</Option>
                      <Option value="部门3">部门3</Option>
                      <Option value="部门4">部门4</Option>
                    </Select>
                  </Space>
                  {/* 只在"我创建的"选项中显示新建按钮 */}
                  {selectedNav === 'created' && (
                    <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
                      新建项目
                    </Button>
                  )}
                </div>
              )}
              {renderContent()}
            </Card>
          </div>
        </Layout>
      </Layout>
      
      {/* 添加编辑弹窗 */}
      <Modal
        title="修改项目信息"
        open={editModalVisible}
        onOk={handleEditSubmit}
        onCancel={() => setEditModalVisible(false)}
        width={600}
      >
        <Form
          form={editForm}
          layout="vertical"
          initialValues={editingProject || {}}
        >
          <Form.Item
            name="name"
            label="项目名称"
            rules={[{ required: true, message: '请输入项目名称' }]}
          >
            <Input placeholder="请输入项目名称" />
          </Form.Item>

          <Form.Item
            name="type"
            label="项目类型"
            rules={[{ required: true, message: '请选择项目类型' }]}
          >
            <Select>
              <Select.Option value="道路工程">道路工程</Select.Option>
              <Select.Option value="桥梁工程">桥梁工程</Select.Option>
              <Select.Option value="隧道工程">隧道工程</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="department"
            label="生产部门"
            rules={[{ required: true, message: '请选择生产部门' }]}
          >
            <Select>
              <Select.Option value="部门1">部门1</Select.Option>
              <Select.Option value="部门2">部门2</Select.Option>
              <Select.Option value="部门3">部门3</Select.Option>
              <Select.Option value="部门4">部门4</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="owner"
            label="项目业主"
            rules={[{ required: true, message: '请输入项目业主' }]}
          >
            <Input placeholder="请输入项目业主" />
          </Form.Item>

          <Form.Item
            name="manager"
            label="项目负责人"
            rules={[{ required: true, message: '请输入项目负责人' }]}
          >
            <Input placeholder="请输入项目负责人" />
          </Form.Item>

          {/* 只读信息展示 */}
          <div className="readonly-info">
            <div className="info-item">
              <span className="label">目编码：</span>
              <span className="value">{editingProject?.code}</span>
            </div>
            <div className="info-item">
              <span className="label">项目创建人：</span>
              <span className="value">{editingProject?.creator}</span>
            </div>
            <div className="info-item">
              <span className="label">创建时间：</span>
              <span className="value">{editingProject?.createTime}</span>
            </div>
          </div>
        </Form>
      </Modal>
      
      {/* 修改新建项目弹窗 */}
      <Modal
        title="新建项目"
        open={addModalVisible}
        onOk={handleAddSubmit}
        onCancel={() => setAddModalVisible(false)}
        width={600}
      >
        <Form
          form={addForm}
          layout="vertical"
        >
          <Form.Item
            name="code"
            label="项目编码"
            rules={[
              { required: true, message: '请输入项目编码' },
              { validator: checkCodeUnique },
              { pattern: /^[A-Za-z0-9-]+$/, message: '项编码只能包含字母、数字和连字符' },
              { min: 4, message: '项编码至少4个字符' },
              { max: 20, message: '项目编码最多20个字符' },
            ]}
            tooltip="项目编码必须唯一，支持字母、数字和连字符，长度4-20位"
          >
            <Input placeholder="请输入项目编码" />
          </Form.Item>

          <Form.Item
            name="name"
            label="项目名称"
            rules={[{ required: true, message: '请输入项目名称' }]}
          >
            <Input placeholder="请输入项目名称" />
          </Form.Item>

          <Form.Item
            name="type"
            label="项目类型"
            rules={[{ required: true, message: '请选择项目类型' }]}
          >
            <Select>
              <Select.Option value="道路工程">道路工程</Select.Option>
              <Select.Option value="桥梁工程">桥梁工程</Select.Option>
              <Select.Option value="隧道工程">隧道工程</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="department"
            label="生产部门"
            rules={[{ required: true, message: '请选择生产部门' }]}
          >
            <Select>
              <Select.Option value="部门1">部门1</Select.Option>
              <Select.Option value="部门2">部门2</Select.Option>
              <Select.Option value="部门3">部门3</Select.Option>
              <Select.Option value="部门4">部门4</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="owner"
            label="项目业主"
            rules={[{ required: true, message: '请输入项目业主' }]}
          >
            <Input placeholder="请输入项目业主" />
          </Form.Item>

          <Form.Item
            name="manager"
            label="项目负责人"
            rules={[{ required: true, message: '请输入项目负责人' }]}
          >
            <Input placeholder="请输入项目负责人" />
          </Form.Item>

          {/* 修改提示信息 */}
          <div className="form-tips">
            <p>注：创建人和创建时间将自动填写。</p>
          </div>
        </Form>
      </Modal>

      {/* 添加应用详情弹窗 */}
      <AppDetailModal
        app={currentApp}
        visible={appDetailVisible}
        onClose={() => setAppDetailVisible(false)}
      />

      {/* 添加材质详情弹窗 */}
      <MaterialDetailModal
        material={currentMaterial}
        visible={materialDetailVisible}
        onClose={() => setMaterialDetailVisible(false)}
      />

      {/* 添加云存储弹窗 */}
      <StorageModal
        visible={storageModalVisible}
        onClose={() => setStorageModalVisible(false)}
      />
    </Layout>
  );
};

export default ProjectList; 