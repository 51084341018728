import React, { useState, useRef } from 'react';
import { Layout, Carousel, Card, Input, Select, Space, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, SearchOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { TrafficApp } from '../../types/app';
import './style.less';
import { generatePlaceholderImage, generateIconImage } from '../../utils/generatePlaceholderImage';

const { Header, Content } = Layout;
const { Search } = Input;

// 添加应用点击处理函数
const handleAppClick = (app: TrafficApp) => {
  if (app.subscribed) {
    message.info('应用详情功能开发中');
  } else {
    message.info('购买功能开发中');
  }
};

// 生成示例图片资源
const appImages = {
  icons: {
    'route-optimizer': generateIconImage('路'),
    'bridge-inspector': generateIconImage('桥'),
    'tunnel-simulator': generateIconImage('隧'),
    'traffic-analyzer': generateIconImage('交'),
    'pavement-inspector': generateIconImage('路'),
    'drainage-designer': generateIconImage('排'),
    'traffic-sign': generateIconImage('标'),
    'bim-platform': generateIconImage('BIM'),
  },
  banners: {
    'route-optimizer': generatePlaceholderImage('路线优化设计助手', 1200, 400),
    'bridge-inspector': generatePlaceholderImage('桥梁结构检测工具', 1200, 400),
    'tunnel-simulator': generatePlaceholderImage('隧道施工模拟器', 1200, 400),
    'traffic-analyzer': generatePlaceholderImage('交通流量分析系统', 1200, 400),
    'pavement-inspector': generatePlaceholderImage('路面质量检测助手', 1200, 400),
    'drainage-designer': generatePlaceholderImage('智能排水设计系统', 1200, 400),
    'traffic-sign': generatePlaceholderImage('交通标志布设助手', 1200, 400),
    'bim-platform': generatePlaceholderImage('BIM协同设计平台', 1200, 400),
  },
  screenshots: {
    'route-optimizer': [
      generatePlaceholderImage('路线优化-主界面', 800, 450),
      generatePlaceholderImage('路线分析', 800, 450),
    ],
    'bridge-inspector': [
      generatePlaceholderImage('桥梁检测-主界面', 800, 450),
      generatePlaceholderImage('结构分析', 800, 450),
    ],
    'tunnel-simulator': [
      generatePlaceholderImage('隧道模拟-主界面', 800, 450),
      generatePlaceholderImage('施工模拟', 800, 450),
    ],
    'traffic-analyzer': [
      generatePlaceholderImage('交通分析-主界面', 800, 450),
      generatePlaceholderImage('流量预测', 800, 450),
    ],
    'pavement-inspector': [
      generatePlaceholderImage('路面检测-主界面', 800, 450),
      generatePlaceholderImage('病害分析', 800, 450),
    ],
    'drainage-designer': [
      generatePlaceholderImage('排水设计-主界面', 800, 450),
      generatePlaceholderImage('水力分析', 800, 450),
    ],
    'traffic-sign': [
      generatePlaceholderImage('标志布设-主界面', 800, 450),
      generatePlaceholderImage('规范检查', 800, 450),
    ],
    'bim-platform': [
      generatePlaceholderImage('BIM平台-主界面', 800, 450),
      generatePlaceholderImage('协同设计', 800, 450),
    ],
  }
};

// 模拟应用数据
const trafficApps: TrafficApp[] = [
  {
    id: 'app1',
    name: '路线优化设计助手',
    icon: appImages.icons['route-optimizer'],
    banner: appImages.banners['route-optimizer'],
    category: '路线设计',
    description: '基于AI的路线方案优化工具，支持多方案对比和分析。',
    version: 'v2.1.0',
    developer: '智能交通研究院',
    price: 2999,
    features: ['AI路线优化', '方案对比', '实时分析'],
    screenshots: appImages.screenshots['route-optimizer'],
    subscribed: true,
    featured: true
  },
  {
    id: 'app2',
    name: '桥梁结构检测工具',
    icon: appImages.icons['bridge-inspector'],
    banner: appImages.banners['bridge-inspector'],
    category: '桥梁检测',
    description: '桥梁结构健康监测和分析系统，支持实时数据采集和预警。',
    version: 'v1.5.2',
    developer: '结构安全研究所',
    price: 1999,
    features: ['结构健康监测', '实时数据采集', '预警分析', '检测报告生成'],
    screenshots: appImages.screenshots['bridge-inspector'],
    subscribed: true,
    featured: true
  },
  {
    id: 'app3',
    name: '隧道施工模拟器',
    icon: appImages.icons['tunnel-simulator'],
    banner: appImages.banners['tunnel-simulator'],
    category: '隧道施工',
    description: '隧道施工过程的3D模拟和分析工具，支持施工方案优化。',
    version: 'v3.0.1',
    developer: '地下工程研究中心',
    price: 3999,
    features: ['施工模拟', '方案优化', '风险评估', '进度管理'],
    screenshots: appImages.screenshots['tunnel-simulator'],
    subscribed: false,
    featured: true
  },
  {
    id: 'app4',
    name: '交通流量分析系统',
    icon: appImages.icons['traffic-analyzer'],
    banner: appImages.banners['traffic-analyzer'],
    category: '交通分析',
    description: '基于大数据的交通流量分析和预测系统。',
    version: 'v2.2.3',
    developer: '智慧交通实验室',
    price: 2499,
    features: ['流量分析', '趋势预测', '拥堵分析', '规划建议'],
    screenshots: appImages.screenshots['traffic-analyzer'],
    subscribed: true,
    featured: false
  },
  {
    id: 'app5',
    name: '路面质量检测助手',
    icon: appImages.icons['pavement-inspector'],
    banner: appImages.banners['pavement-inspector'],
    category: '路面检测',
    description: '智能路面病害识别与评估系统，支持移动设备采集数据。',
    version: 'v1.8.0',
    developer: '道路工程研究院',
    price: 1799,
    features: ['病害识别', '评估分析', '维修建议', '报告导出'],
    screenshots: appImages.screenshots['pavement-inspector'],
    subscribed: false,
    featured: false
  },
  {
    id: 'app6',
    name: '智能排水设计系统',
    icon: appImages.icons['drainage-designer'],
    banner: appImages.banners['drainage-designer'],
    category: '排水设计',
    description: '道路排水系统智能设计工具，支持多种工况分析。',
    version: 'v2.0.5',
    developer: '市政工程设计院',
    price: 2299,
    features: ['智能选型', '水力计算', '方案优化', '图纸生成'],
    screenshots: appImages.screenshots['drainage-designer'],
    subscribed: false,
    featured: false
  },
  {
    id: 'app7',
    name: '交通标志布设助手',
    icon: appImages.icons['traffic-sign'],
    banner: appImages.banners['traffic-sign'],
    category: '交通设施',
    description: '交通标志标线智能布设系统，支持规范检查。',
    version: 'v1.3.1',
    developer: '交通设施研究所',
    price: 1599,
    features: ['智能布设', '规范检查', '工程量统计', '图纸输出'],
    screenshots: appImages.screenshots['traffic-sign'],
    subscribed: false,
    featured: false
  },
  {
    id: 'app8',
    name: 'BIM协同设计平台',
    icon: appImages.icons['bim-platform'],
    banner: appImages.banners['bim-platform'],
    category: '协同设计',
    description: '支持多专业协同设计的BIM平台，实时数据同步。',
    version: 'v4.2.0',
    developer: 'BIM技术中心',
    price: 4999,
    features: ['多人协作', '实时同步', '版本控制', '冲突检查'],
    screenshots: appImages.screenshots['bim-platform'],
    subscribed: false,
    featured: true
  }
];

const AppStore: React.FC = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState<string>('name');
  const carouselRef = useRef<any>(null);

  // 处理搜索
  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  // 处理搜索类型切换
  const handleSearchTypeChange = (value: string) => {
    setSearchType(value);
  };

  // 处理轮播图切换
  const handleCarouselPrev = () => {
    carouselRef.current?.prev();
  };

  const handleCarouselNext = () => {
    carouselRef.current?.next();
  };

  return (
    <Layout className="app-store">
      <Header className="header">
        <div className="header-left">
          <Button 
            type="link" 
            icon={<ArrowLeftOutlined />} 
            onClick={() => navigate(-1)}
            className="back-button"
          >
            返回
          </Button>
          <span className="page-title">应用市场</span>
        </div>
      </Header>
      
      <Content className="content">
        {/* 热门应用轮播 */}
        <div className="banner-section">
          <Carousel 
            autoplay 
            effect="fade" 
            ref={carouselRef}
          >
            {trafficApps
              .filter(app => app.featured)
              .map(app => (
                <div key={app.id} className="banner-item">
                  <img src={app.banner} alt={app.name} />
                  <div className="banner-content">
                    <h2>{app.name}</h2>
                    <p>{app.description}</p>
                  </div>
                </div>
              ))}
          </Carousel>
          <div className="carousel-arrows">
            <div className="arrow-button prev" onClick={handleCarouselPrev}>
              <LeftOutlined />
            </div>
            <div className="arrow-button next" onClick={handleCarouselNext}>
              <RightOutlined />
            </div>
          </div>
        </div>

        {/* 搜索工具栏 */}
        <div className="search-toolbar">
          <div className="toolbar-left">
            <h2>全部应用</h2>
            <span className="app-count">{trafficApps.length}个应用</span>
          </div>
          <div className="toolbar-right">
            <Space size="large">
              <Select
                defaultValue="name"
                style={{ width: 100 }}
                onChange={handleSearchTypeChange}
                options={[
                  { value: 'name', label: '名称' },
                  { value: 'developer', label: '开发者' },
                ]}
              />
              <Search
                placeholder="搜索应用"
                allowClear
                enterButton={<SearchOutlined />}
                onSearch={handleSearch}
                style={{ width: 300 }}
              />
            </Space>
          </div>
        </div>

        {/* 应用列表 */}
        <div className="app-list">
          <div className="app-grid">
            {trafficApps
              .filter(app => {
                if (!searchText) return true;
                if (searchType === 'name') {
                  return app.name.toLowerCase().includes(searchText.toLowerCase());
                }
                return app.developer.toLowerCase().includes(searchText.toLowerCase());
              })
              .map(app => (
                <Card
                  key={app.id}
                  hoverable
                  className="app-card"
                  onClick={() => handleAppClick(app)}
                >
                  <div className="app-header">
                    <img src={app.icon} alt={app.name} className="app-icon" />
                    <div className="app-info">
                      <div className="app-name">{app.name}</div>
                      <div className="app-category">{app.category}</div>
                    </div>
                  </div>
                  <div className="app-description">{app.description}</div>
                  <div className="app-footer">
                    <span className="app-version">{app.version}</span>
                    <span className="app-developer">{app.developer}</span>
                  </div>
                </Card>
              ))}
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default AppStore; 