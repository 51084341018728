import React, { useState } from 'react';
import { Layout, Menu, Card, Button, Space, Dropdown, message, Input, Modal, Form, Table, Select } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { ProjectOutlined, ArrowLeftOutlined, UserOutlined, LogoutOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import './style.less';
import ThemeSwitch from '../../components/ThemeSwitch';

const { Sider, Content, Header } = Layout;

interface Engineering {
  id: string;
  name: string;
  specialty: string;
  isEditing?: boolean;
}

const specialties = [
  { key: 'route', label: '路线', icon: <ProjectOutlined /> },
  { key: 'survey', label: '勘察', icon: <ProjectOutlined /> },
  { key: 'bridge', label: '桥梁', icon: <ProjectOutlined /> },
  { key: 'tunnel', label: '隧道', icon: <ProjectOutlined /> },
  { key: 'subgrade', label: '路基', icon: <ProjectOutlined /> },
  { key: 'pavement', label: '路面', icon: <ProjectOutlined /> },
  { key: 'culvert', label: '涵洞', icon: <ProjectOutlined /> },
  { key: 'traffic', label: '交安', icon: <ProjectOutlined /> },
  { key: 'mechanical', label: '机电', icon: <ProjectOutlined /> },
];

// 添加专业组件配置接口
interface ProfessionConfig {
  key: string;
  profession: string;
  componentType: string;
  description: string;
}

// 添加组件类型接口
interface ComponentType {
  value: string;
  label: string;
  description: string;
  category: string;
}

// 添加 Select 选项的类型定义
interface ComponentSelectOption {
  value: string;
  label: React.ReactNode;
  title?: string;
}

const EngineeringList: React.FC = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [selectedSpecialty, setSelectedSpecialty] = useState('route');
  const [engineeringList, setEngineeringList] = useState<Engineering[]>(
    Array.from({ length: 100 }, (_, index) => ({
      id: `eng-${index}`,
      name: `路线工程${index + 1}`,
      specialty: 'route',
      isEditing: false,
    }))
  );

  const handleStartRename = (eng: Engineering) => {
    setEngineeringList(list =>
      list.map(item =>
        item.id === eng.id ? { ...item, isEditing: true } : item
      )
    );
  };

  const handleRename = (eng: Engineering, newName: string) => {
    if (!newName.trim()) {
      message.error('工程名称不能为空');
      return;
    }
    setEngineeringList(list =>
      list.map(item =>
        item.id === eng.id
          ? { ...item, name: newName.trim(), isEditing: false }
          : item
      )
    );
    message.success('重命名成功');
  };

  const handleCancelRename = (eng: Engineering) => {
    setEngineeringList(list =>
      list.map(item =>
        item.id === eng.id ? { ...item, isEditing: false } : item
      )
    );
  };

  const filteredEngineering = selectedSpecialty 
    ? engineeringList.filter(eng => eng.specialty === selectedSpecialty)
    : engineeringList;

  // 模拟用户信息
  const userInfo = {
    name: '张三',
    role: '管理员',
  };

  // 用户菜单项
  const userMenuItems: MenuProps['items'] = [
    {
      key: 'profile',
      icon: <UserOutlined />,
      label: '个人信息',
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: '退出登录',
      danger: true,
    },
  ];

  // 处理菜单点击
  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'profile':
        // 这里可以添加查看个人信息的逻辑，比如打开一个模态框
        message.info('个人信息功能开发中');
        break;
      case 'logout':
        message.success('已安全退出');
        navigate('/login');
        break;
      default:
        break;
    }
  };

  // 添加删除确认状态
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [engineeringToDelete, setEngineeringToDelete] = useState<Engineering | null>(null);

  // 处理删除
  const handleDelete = (eng: Engineering) => {
    setEngineeringToDelete(eng);
    setDeleteModalVisible(true);
  };

  // 确认删除
  const confirmDelete = () => {
    if (engineeringToDelete) {
      setEngineeringList(list => list.filter(item => item.id !== engineeringToDelete.id));
      message.success('删除成功');
      setDeleteModalVisible(false);
      setEngineeringToDelete(null);
    }
  };

  // 卡片右键菜单项
  const getCardContextMenu = (eng: Engineering): MenuProps['items'] => [
    {
      key: 'rename',
      icon: <EditOutlined />,
      label: '重命名',
      onClick: () => handleStartRename(eng),
    },
    {
      key: 'delete',
      icon: <DeleteOutlined />,
      label: '删除',
      danger: true,
      onClick: () => handleDelete(eng),
    },
  ];

  // 添加新建工程的状态和函数
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [addForm] = Form.useForm();

  const handleAdd = () => {
    addForm.resetFields();
    setAddModalVisible(true);
  };

  const handleAddSubmit = async () => {
    try {
      const values = await addForm.validateFields();
      const newEngineering: Engineering = {
        id: `eng-${engineeringList.length}`,
        name: values.name,
        specialty: selectedSpecialty,
        isEditing: false,
      };
      setEngineeringList([...engineeringList, newEngineering]);
      message.success('创建成功');
      setAddModalVisible(false);
    } catch (error) {
      message.error('请检查表单填写是否正确');
    }
  };

  // 同步组件类型选项
  const componentTypes: ComponentType[] = [
    {
      value: 'bridge-upper',
      label: '桥梁上部构件',
      description: '包含箱梁、T梁等桥梁上部结构构件',
      category: '桥梁'
    },
    {
      value: 'bridge-lower',
      label: '桥梁下部构件',
      description: '包含桥墩、桥台等桥梁下部结构构件',
      category: '桥梁'
    },
    {
      value: 'tunnel-lining',
      label: '隧道衬砌构件',
      description: '包含各类隧道衬砌结构构件',
      category: '隧道'
    },
    {
      value: 'tunnel-waterproof',
      label: '隧道防水构件',
      description: '包含防水板、防水层等构件',
      category: '隧道'
    },
    {
      value: 'road-subgrade',
      label: '路基构件',
      description: '包含路基防护、加筋等构件',
      category: '路基'
    },
    {
      value: 'road-pavement',
      label: '路面构件',
      description: '包含沥青路面、水泥路面等构件',
      category: '路面'
    },
    {
      value: 'drainage-pipe',
      label: '排水管网构件',
      description: '包含各类排水管道、检查井等构件',
      category: '排水'
    },
    {
      value: 'traffic-facilities',
      label: '交通设施构件',
      description: '包含标志、标线、护栏等构件',
      category: '交安'
    }
  ];

  // 更新专业配置数据
  const [professionConfigs, setProfessionConfigs] = useState<ProfessionConfig[]>([
    {
      key: '1',
      profession: '桥梁专业',
      componentType: 'bridge-upper',
      description: '用于桥梁设计的标准构件'
    },
    {
      key: '2',
      profession: '隧道专业',
      componentType: 'tunnel-lining',
      description: '用于隧道设计的标准构件'
    },
    {
      key: '3',
      profession: '路基专业',
      componentType: 'road-subgrade',
      description: '用于路基设计的标准构件'
    },
    {
      key: '4',
      profession: '路面专业',
      componentType: 'road-pavement',
      description: '用于路面设计的标准构件'
    }
  ]);

  // 修改配置表格列定义
  const configColumns = [
    {
      title: '专业名称',
      dataIndex: 'profession',
      key: 'profession',
      width: 160,  // 调整为更合适的宽度
    },
    {
      title: '组件类型',
      dataIndex: 'componentType',
      key: 'componentType',
      width: undefined,  // 移除固定宽度，让它自动适应
      render: (text: string, record: ProfessionConfig) => (
        <Select
          value={text}
          style={{ width: '100%' }}
          onChange={(value) => handleComponentTypeChange(value, record)}
          options={componentTypes.map(type => ({
            value: type.value,
            label: (
              <div className="component-type-option">
                <span className="type-name">{type.label}</span>
                <span className="type-category">{type.category}</span>
              </div>
            ),
            title: type.description
          }))}
          optionLabelProp="label"
          showSearch
          filterOption={(input: string, option?: ComponentSelectOption) => {
            if (!option?.title) return false;
            return option.title.toLowerCase().includes(input.toLowerCase());
          }}
        />
      ),
    }
  ];

  // 处理组件类型修改
  const handleComponentTypeChange = (value: string, record: ProfessionConfig) => {
    const newConfigs = professionConfigs.map(config => {
      if (config.key === record.key) {
        return { ...config, componentType: value };
      }
      return config;
    });
    setProfessionConfigs(newConfigs);
  };

  // 添加工具栏按钮
  const toolbarButtons = (
    <Space>
      <ThemeSwitch />
      <Dropdown
        menu={{
          items: userMenuItems,
          onClick: handleMenuClick,
        }}
        placement="bottomRight"
      >
        <Button type="link" className="user-info">
          <UserOutlined /> {userInfo.name}
          <span className="user-role">({userInfo.role})</span>
        </Button>
      </Dropdown>
      <Button
        icon={<SettingOutlined />}
        onClick={() => setConfigModalVisible(true)}
      >
        专业定制
      </Button>
    </Space>
  );

  // 添加专业配置弹窗状态
  const [configModalVisible, setConfigModalVisible] = useState(false);

  return (
    <Layout className="engineering-list">
      <Header className="header">
        <div className="header-left">
          <span className="platform-name">超融合BIM图形平台</span>
          <div className="divider" />
          <span className="page-title">工程列表</span>
        </div>
        <div className="header-right">
          <Space>
            {toolbarButtons}
          </Space>
        </div>
      </Header>
      <Layout className="main-content">
        <Sider width={180} className="specialty-sider" theme="dark">
          <div className="sider-header">
            <Button 
              type="link" 
              icon={<ArrowLeftOutlined />} 
              onClick={() => navigate('/projects')}
              className="back-button"
            >
              返回项目列表
            </Button>
          </div>
          <Menu
            mode="inline"
            selectedKeys={[selectedSpecialty]}
            style={{ height: 'calc(100% - 50px)' }}
            onClick={({ key }) => setSelectedSpecialty(key)}
            theme="dark"
          >
            {specialties.map(specialty => (
              <Menu.Item key={specialty.key} icon={specialty.icon}>
                {specialty.label}
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Content className="engineering-content">
          <div className="row-wrapper">
            {/* 添加新建卡片 */}
            <Card 
              hoverable 
              className="engineering-card add-card"
              onClick={handleAdd}
            >
              <div className="add-card-content">
                <PlusOutlined className="add-icon" />
                <div className="add-text">新建工程</div>
              </div>
            </Card>
            {/* 现有工程卡片保持不变 */}
            {filteredEngineering.map(eng => (
              <Dropdown
                key={eng.id}
                menu={{ items: getCardContextMenu(eng) }}
                trigger={['contextMenu']}
              >
                <Card 
                  hoverable 
                  className="engineering-card"
                  onClick={() => navigate(`/engineering/${eng.id}/cad`)}
                  cover={
                    <div className="engineering-cover">
                      <ProjectOutlined className="engineering-icon" />
                    </div>
                  }
                >
                  <Card.Meta
                    title={
                      eng.isEditing ? (
                        <div className="rename-input">
                          <Input
                            defaultValue={eng.name}
                            autoFocus
                            onPressEnter={(e) => handleRename(eng, (e.target as HTMLInputElement).value)}
                            onBlur={(e) => handleRename(eng, e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Escape') {
                                handleCancelRename(eng);
                              }
                            }}
                          />
                        </div>
                      ) : (
                        eng.name
                      )
                    }
                  />
                </Card>
              </Dropdown>
            ))}
          </div>
        </Content>
      </Layout>

      {/* 删除确认对话框 */}
      <Modal
        title="确认删除"
        open={deleteModalVisible}
        onOk={confirmDelete}
        onCancel={() => {
          setDeleteModalVisible(false);
          setEngineeringToDelete(null);
        }}
        okText="确认"
        cancelText="取消"
      >
        <p>确定要删除工程 "{engineeringToDelete?.name}" 吗？</p>
      </Modal>

      {/* 添加新建工程对话框 */}
      <Modal
        title="新建工程"
        open={addModalVisible}
        onOk={handleAddSubmit}
        onCancel={() => setAddModalVisible(false)}
        okText="确认"
        cancelText="取消"
      >
        <Form
          form={addForm}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="工程名称"
            rules={[
              { required: true, message: '请输入工程名称' },
              { max: 50, message: '工程名称最多50个字符' }
            ]}
          >
            <Input placeholder="请输入工程名称" />
          </Form.Item>
        </Form>
      </Modal>

      {/* 添加专业配置弹窗 */}
      <Modal
        title="专业组件配置"
        open={configModalVisible}
        onOk={() => {
          message.success('配置已保存');
          setConfigModalVisible(false);
        }}
        onCancel={() => setConfigModalVisible(false)}
        width={800}
      >
        <div className="config-description">
          <p>为每个专业配置对应的组件类型，配置后将影响工程中的可用工具。</p>
        </div>
        <Table
          columns={configColumns}
          dataSource={professionConfigs}
          pagination={false}
          size="middle"
          className="config-table"
        />
      </Modal>
    </Layout>
  );
};

export default EngineeringList; 