import React from 'react';
import { Layout, Menu, Tree, Tabs } from 'antd';
import { FileOutlined, FolderOutlined } from '@ant-design/icons';
import './style.less';

const { Header, Sider, Content, Footer } = Layout;

interface CADViewerProps {
  engineeringId?: string;
}

const CADViewer: React.FC<CADViewerProps> = ({ engineeringId }) => {
  // Ribbon菜单数据
  const ribbonItems = [
    {
      key: 'file',
      label: '文件',
      children: [
        { key: 'new', label: '新建' },
        { key: 'open', label: '打开' },
        { key: 'save', label: '保存' },
      ]
    },
    {
      key: 'edit',
      label: '编辑',
      children: [
        { key: 'undo', label: '撤销' },
        { key: 'redo', label: '重做' },
        { key: 'copy', label: '复制' },
        { key: 'paste', label: '粘贴' },
      ]
    },
    {
      key: 'view',
      label: '视图',
      children: [
        { key: 'pan', label: '平移' },
        { key: 'zoom', label: '缩放' },
        { key: 'fit', label: '适应窗口' },
      ]
    },
    {
      key: 'draw',
      label: '绘图',
      children: [
        { key: 'line', label: '直线' },
        { key: 'circle', label: '圆' },
        { key: 'rectangle', label: '矩形' },
      ]
    },
  ];

  // 工程目录树数据
  const treeData = [
    {
      title: '工程文件',
      key: '0',
      icon: <FolderOutlined />,
      children: [
        {
          title: '图纸',
          key: '0-0',
          icon: <FolderOutlined />,
          children: [
            { title: '总平面图.dwg', key: '0-0-0', icon: <FileOutlined /> },
            { title: '横断面图.dwg', key: '0-0-1', icon: <FileOutlined /> },
            { title: '纵断面图.dwg', key: '0-0-2', icon: <FileOutlined /> },
          ],
        },
        {
          title: '模型',
          key: '0-1',
          icon: <FolderOutlined />,
          children: [
            { title: '路基模型.dwg', key: '0-1-0', icon: <FileOutlined /> },
            { title: '路面模型.dwg', key: '0-1-1', icon: <FileOutlined /> },
          ],
        },
      ],
    },
  ];

  return (
    <Layout className="cad-viewer">
      {/* Ribbon菜单区 */}
      <Header className="ribbon-header">
        <Menu
          mode="horizontal"
          items={ribbonItems}
          className="ribbon-menu"
        />
      </Header>

      <Layout>
        {/* 工程目录树 */}
        <Sider width={250} className="project-sider">
          <div className="sider-header">工程目录</div>
          <Tree
            showIcon
            defaultExpandAll
            treeData={treeData}
          />
        </Sider>

        <Layout>
          {/* 绘图区 */}
          <Content className="drawing-area">
            <div className="canvas-container">
              {/* 这里可以集成实际的CAD绘图组件 */}
              <div className="placeholder-text">CAD绘图区</div>
            </div>
          </Content>

          {/* 属性栏 */}
          <Sider width={300} className="property-sider">
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  key: '1',
                  label: '属性',
                  children: <div className="property-panel">属性面板内容</div>,
                },
                {
                  key: '2',
                  label: '图层',
                  children: <div className="layer-panel">图层面板内容</div>,
                },
              ]}
            />
          </Sider>
        </Layout>
      </Layout>

      {/* 状态栏 */}
      <Footer className="status-bar">
        <div className="status-item">就绪</div>
        <div className="status-item">坐标: 0,0,0</div>
        <div className="status-item">比例: 1:1</div>
        <div className="status-item">图层: 0</div>
      </Footer>
    </Layout>
  );
};

export default CADViewer; 