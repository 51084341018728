import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../pages/Login';
import ProjectList from '../pages/ProjectList';
import EngineeringList from '../pages/EngineeringList';
import CADViewer from '../pages/CADViewer';
import ComponentDetail from '../pages/ComponentDetail';
import TemplateDetail from '../pages/TemplateDetail';
import AppStore from '../pages/AppStore';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/projects" element={<ProjectList />} />
      <Route path="/projects/:projectId/engineering" element={<EngineeringList />} />
      <Route path="/engineering/:engineeringId/cad" element={<CADViewer />} />
      <Route path="/components/:componentId" element={<ComponentDetail />} />
      <Route path="/templates/:templateId" element={<TemplateDetail />} />
      <Route path="/appstore/all" element={<AppStore />} />
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default AppRoutes; 